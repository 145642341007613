import React from 'react';
import PropTypes from 'prop-types';

const QuoteBlock = ({ text, author, authorTitle }) => {
  return text ? (
    <figure className="quote-block">
      <blockquote className="quote-block__text">
        <span className="quote-block__top-line" role="presentation"></span>
        {text}
      </blockquote>
      {(author || authorTitle) && (
        <figcaption className="quote-block__caption">
          {author && <div className="quote-block__author">{author}</div>}
          {authorTitle && (
            <div className="quote-block__title">{authorTitle}</div>
          )}
        </figcaption>
      )}
    </figure>
  ) : null;
};

QuoteBlock.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
  authorTitle: PropTypes.string,
};

export default QuoteBlock;
