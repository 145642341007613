import fromQueryString from '@creuna/utils/from-query-string';
import replaceQueryParameters from '@creuna/utils/replace-query-parameters';

const _inQueryStringArrayDelimiter = '~';

const _getUndefinedIfEmpty = value => {
  return !value || value === '' || value.length === 0 ? undefined : value;
};

const _queryValueAsArray = value => {
  value = value || [];
  return Array.isArray(value) ? value : value.split(',');
};

const createQuery = () => {
  return new Object();
};

const setQueryValue = (query, name, value) => {
  query[name] = _getUndefinedIfEmpty(value);
};

const setToQueryTextInputValue = (query, textInput, currentValue = null) => {
  if (!textInput) {
    return;
  }
  setQueryValue(query, textInput.name, currentValue ?? textInput.value);
};

const updateFilterFromQuery = (query, fieldName, filterItems) => {
  var queryValues = _queryValueAsArray(query[fieldName]);
  filterItems.forEach(item => {
    item.selected = queryValues.includes(item.value);
  });
};

const updateQueryFromFilter = (query, fieldName, filterItems) => {
  var filterValues = filterItems.map(x => x.value);
  var filterSelectedValues = filterItems
    .filter(x => x.selected)
    .map(x => x.value);
  var queryValues = _queryValueAsArray(query[fieldName]);
  var newValues = [
    ...new Set([...queryValues, ...filterSelectedValues]),
  ].filter(x => !filterValues.includes(x) || filterSelectedValues.includes(x));

  setQueryValue(query, fieldName, newValues);
};

const updatePageQueryString = query => {
  query = { ...query };
  for (var key in query) {
    var value = query[key];
    if (Array.isArray(value))
      query[key] = value.join(_inQueryStringArrayDelimiter);
  }
  var newUrl = replaceQueryParameters(window.location.toString(), query);
  window.history.replaceState({}, document.title, newUrl);
};

const fillQueryFromPageQueryString = query => {
  var parsedQuery = fromQueryString(window.location.search.replace(/\+/g, ' '));
  for (var key in parsedQuery) {
    var value = parsedQuery[key];
    if (value.includes(_inQueryStringArrayDelimiter))
      parsedQuery[key] = value.split(_inQueryStringArrayDelimiter);
  }
  Object.assign(query, parsedQuery);
};

export default {
  createQuery,
  setQueryValue,
  updateFilterFromQuery,
  updateQueryFromFilter,
  setToQueryTextInputValue,
  updatePageQueryString,
  fillQueryFromPageQueryString,
};
