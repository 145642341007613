import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo';
import MainMenu from 'components/main-menu';
import Button from 'components/button';
import SecondaryMenu from 'components/secondary-menu';
import QuickSearch from 'components/quick-search';
import useToggle from 'hooks/use-toggle';
import useEscape from 'hooks/use-escape';
import useClickOutside from 'hooks/use-click-outside';
import { getNumericCssPropertyValue } from 'js/stylesheet-utils';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';

const headerMenuId = 'header-menu';
const bodyActiveClass = 'header-menu-is-opened';

const Header = ({ logo, mainMenu, quickSearch, secondaryMenu, lang }) => {
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const headerAnimationTimeout = getNumericCssPropertyValue(
      '--header-animation-timeout'
    );

    setTimeout(() => {
      setIsAnimated(true);
    }, headerAnimationTimeout);
  }, []);

  const {
    isActive: isMenuActive,
    toggle: toggleMenu,
    deactivate: deactivateMenu,
  } = useToggle(false);
  const {
    isActive: isSearchExpanded,
    activate: activateSearch,
    deactivate: deactivateSearch,
  } = useToggle(false);

  const headerRef = useRef(null);

  const closeHeader = () => {
    deactivateMenu();
    deactivateSearch();
  };

  useEscape(closeHeader);
  useClickOutside(headerRef, closeHeader);

  React.useEffect(() => {
    if (isMenuActive) {
      activateSearch();
      document.body.classList.add(bodyActiveClass);
    } else {
      deactivateSearch();
      document.body.classList.remove(bodyActiveClass);
    }
  }, [isMenuActive]);

  return (
    <FocusTrap
      active={isMenuActive}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div
        className={cn('header', {
          'header_menu-opened': isMenuActive,
          'header_search-expanded': isSearchExpanded,
          header_animated: isAnimated,
        })}
        ref={headerRef}
      >
        <div className="header__top-container">
          {logo && logo.url && (
            <Logo
              {...logo}
              className="header__logo"
              imageClass="image_size_header-logo"
            />
          )}
          {quickSearch && (
            <QuickSearch
              {...quickSearch}
              className="header__quick-search"
              isSearchExpanded={isSearchExpanded}
              onSearchExpand={activateSearch}
            />
          )}
          {(mainMenu || secondaryMenu) && (
            <Button
              onClick={toggleMenu}
              className="header__menu-button"
              text={isMenuActive ? lang.closeMenuLabel : lang.menuLabel}
              theme="light"
              iconName={isMenuActive ? 'menu-close' : 'menu'}
              ariaAttributes={{
                'aria-label': isMenuActive
                  ? lang.closeMenuLabel
                  : lang.menuLabel,
                'aria-haspopup': true,
                'aria-controls': headerMenuId,
              }}
            />
          )}
        </div>
        <div
          className={cn('header__menu', {
            header__menu_opened: isMenuActive,
          })}
          id={headerMenuId}
          role="dialog"
          aria-hidden={!isMenuActive}
          aria-label={lang.menuLabel}
        >
          <div className="header__menu-container">
            <nav className="header__menu-wrapper">
              {mainMenu && (
                <MainMenu {...mainMenu} className="header__main-menu" />
              )}
              {secondaryMenu && (
                <SecondaryMenu
                  {...secondaryMenu}
                  className="header__secondary-menu"
                />
              )}
            </nav>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

Header.propTypes = {
  logo: PropTypes.exact(Logo.propTypes),
  quickSearch: PropTypes.exact(QuickSearch.propTypes),
  mainMenu: PropTypes.exact(MainMenu.propTypes),
  secondaryMenu: PropTypes.exact(SecondaryMenu.propTypes),
  lang: PropTypes.shape({
    menuLabel: PropTypes.string.isRequired,
    closeMenuLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;
