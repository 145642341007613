import PropTypes from 'prop-types';
import ReactComponent from 'components/react-component/react-component';
import ToggleFilter from 'components/toggle-filter/toggle-filter';

const SearchResults = {};

SearchResults.propTypes = {
  pageTypeFilter: PropTypes.exact(ToggleFilter.propTypes),
  totalCount: PropTypes.number.isRequired,
  hits: PropTypes.arrayOf(PropTypes.shape(ReactComponent.propTypes)),
  pager: PropTypes.shape({
    page: PropTypes.number,
    totalPages: PropTypes.number,
  }),
};

export default SearchResults;
