import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/icon-button';
import cn from 'classnames';

export const arrowDirections = {
  right: 'right',
  left: 'left',
};

const RecipeTeasersBlockSliderNavButton = ({
  onClick,
  text,
  isDisabled,
  arrowDirection,
}) => {
  return (
    <IconButton
      iconName={
        arrowDirection === arrowDirections.left ? 'arrow-left' : 'arrow-right'
      }
      onClick={onClick}
      isDisabled={isDisabled}
      className={cn('recipe-teasers-block__slider-nav-button', {
        'recipe-teasers-block__slider-nav-button_prev':
          arrowDirection === arrowDirections.left,
        'recipe-teasers-block__slider-nav-button_next':
          arrowDirection === arrowDirections.right,
        'recipe-teasers-block__slider-nav-button_disabled': isDisabled,
      })}
      ariaAttributes={{
        'aria-label': text,
      }}
    />
  );
};

RecipeTeasersBlockSliderNavButton.viewModelMeta = 'ignore';

RecipeTeasersBlockSliderNavButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
  arrowDirection: PropTypes.oneOf(Object.values(arrowDirections)),
};

export default RecipeTeasersBlockSliderNavButton;
