import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';

const RichTextBlock = ({ textHtml }) => {
  return (
    <div className="rich-text-block">
      <RichText text={textHtml} />
    </div>
  );
};

RichTextBlock.propTypes = {
  textHtml: PropTypes.string,
};

export default RichTextBlock;
