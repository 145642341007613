import React from 'react';
import PropTypes from 'prop-types';
import PageHeading from 'components/page-heading';
import PoorText from 'components/poor-text';
import ParallaxHeroBackground from 'components/parallax-hero-background';
import Link from 'components/link';
import LinkButton, {
  sizes as linkButtonSizes,
  arrowDirections as linkButtonArrowDirections,
} from 'components/link-button';
import useAnimationStepSwitcher, {
  ANIMATION_STEPS,
} from 'hooks/use-animation-step-switcher';
import cn from 'classnames';

const HowToPageHero = ({ lang, heading, introHtml, allGuidesLink }) => {
  const animationCurrentStep = useAnimationStepSwitcher(
    ANIMATION_STEPS.oneLayerHero
  );

  return (
    <div className="how-to-page-hero">
      <ParallaxHeroBackground
        backgroundColor="#FFAE9C"
        className={cn('how-to-page-hero__background', {
          'how-to-page-hero__background_visible': animationCurrentStep > 0,
        })}
      />
      <div
        className={cn('how-to-page-hero__container', {
          'how-to-page-hero__container_visible': animationCurrentStep > 1,
          'how-to-page-hero__container_tall': animationCurrentStep > 2,
        })}
      >
        <div className="how-to-page-hero__content">
          {allGuidesLink && (
            <LinkButton
              className="how-to-page-hero__all-guides-link"
              size={linkButtonSizes.small}
              arrowDirection={linkButtonArrowDirections.left}
              {...allGuidesLink}
            />
          )}
          <strong className="how-to-page-hero__label">{lang.label}</strong>
          {heading && (
            <PageHeading text={heading} className="how-to-page-hero__heading" />
          )}
          {introHtml && (
            <PoorText
              text={introHtml}
              className="poor-text_in_how-to-page-hero"
            />
          )}
        </div>
      </div>
    </div>
  );
};

HowToPageHero.propTypes = {
  lang: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  allGuidesLink: PropTypes.exact(Link.propTypes),
};

export default HowToPageHero;
