import React from 'react';
import MenuLink from 'components/models/menu-link';
import LinkButton, { themes as LinkButtonThemes } from 'components/link-button';
import PropTypes from 'prop-types';
import cn from 'classnames';

const MainMenu = ({ links, className }) => {
  return links && links.length > 0 ? (
    <ul className={cn('main-menu', className)}>
      {links.map((link, index) => (
        <li key={index} className="main-menu__item">
          <LinkButton
            {...link}
            theme={LinkButtonThemes.light}
            reverseIconDirectionOnHover
            className={cn('main-menu__link', {
              'main-menu__link_active': link.isActive,
            })}
          />
        </li>
      ))}
    </ul>
  ) : null;
};

MainMenu.viewModelMeta = {
  className: 'ignore',
};

MainMenu.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)).isRequired,
};

export default MainMenu;
