import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon';

const RateRecipeItem = ({
  className,
  isActive,
  value,
  hint,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onClick = () => {},
  onFocus = () => {},
  onBlur = () => {},
  isDisabled = false,
}) => {
  return (
    <button
      className={cn('rate-recipe__item', className, {
        'rate-recipe__item_active': isActive,
      })}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      disabled={isDisabled}
      aria-label={hint}
      value={value}
    >
      <Icon
        className="rate-recipe__item-icon rate-recipe__item-icon_empty"
        name="star-empty"
      />
      <Icon
        name="star-full"
        className="rate-recipe__item-icon rate-recipe__item-icon_full"
      />
    </button>
  );
};

RateRecipeItem.viewModelMeta = {
  className: 'ignore',
  isActive: 'ignore',
  isDisabled: 'ignore',
  onFocus: 'ignore',
  onMouseLeave: 'ignore',
  onBlur: 'ignore',
};

RateRecipeItem.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  value: PropTypes.number,
  hint: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onBlur: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default RateRecipeItem;
