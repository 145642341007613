import React, { useEffect } from 'react';
import breakpoints from 'js/breakpoints';

export default function useBreakpoints(
  initiallyDesktop,
  initiallyTablet,
  initiallyMobile,
  initiallyDesktopLargerThanIpad,
  initiallyDesktopExtraLarge
) {
  const [state, setState] = React.useState({
    isDesktop: initiallyDesktop,
    isTablet: initiallyTablet,
    isMobile: initiallyMobile,
    isDesktopLargerThanIpad: initiallyDesktopLargerThanIpad,
    isDesktopExtraLarge: initiallyDesktopExtraLarge,
    brekpointsStarted: false,
  });

  const onResize = () => {
    setState({
      isDesktop: breakpoints.l(),
      isTablet: breakpoints.m() && !breakpoints.l(),
      isMobile: breakpoints.s() && !breakpoints.m(),
      isDesktopLargerThanIpad: breakpoints.ipadPro(),
      isDesktopExtraLarge: breakpoints.xl(),
      brekpointsStarted: true,
    });
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return state;
}
