import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import { Parallax } from 'react-parallax';

const ParallaxBgImage = ({ imageClassName, backgroundImage }) => {
  return (
    <Parallax
      className="parallax-bg-image__scroll-container"
      style={{
        overflow: 'visible',
        position: 'absolute',
      }}
      renderLayer={percentage => (
        <>
          <div
            className="parallax-bg-image__image-container"
            style={{
              top: `-${
                percentage > 0
                  ? percentage <= 1.5
                    ? percentage * 26.66
                    : 40
                  : 0
              }%`,
            }}
          >
            {backgroundImage && (
              <Image
                {...backgroundImage}
                fluid={true} //handled by css
                className={imageClassName}
              />
            )}
          </div>
        </>
      )}
    ></Parallax>
  );
};

ParallaxBgImage.viewModelMeta = 'ignore';

ParallaxBgImage.propTypes = {
  imageClassName: PropTypes.string,
  backgroundImage: PropTypes.exact(Image.propTypes),
};

export default ParallaxBgImage;
