import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Link from 'components/link';
import LinkButton, {
  sizes as linkButtonSizes,
  arrowDirections as linkButtonArrowDirections,
} from 'components/link-button';
import PageHeading from 'components/page-heading';
import ParallaxHeroBackground from 'components/parallax-hero-background';
import PoorText from 'components/poor-text';
import useAnimationStepSwitcher, {
  ANIMATION_STEPS,
} from 'hooks/use-animation-step-switcher';

import cn from 'classnames';

const ShortArticlePageHero = ({
  heading,
  introHtml,
  image,
  imageCaption,
  allStoriesLink,
  backgroundColor,
}) => {
  const animationCurrentStep = useAnimationStepSwitcher(
    ANIMATION_STEPS.oneLayerHero
  );

  return (
    <div className="short-article-page-hero">
      <ParallaxHeroBackground
        backgroundColor={backgroundColor}
        className={cn('short-article-page-hero__background', {
          'short-article-page-hero__background_visible':
            animationCurrentStep > 0,
        })}
      />
      <div
        className={cn('short-article-page-hero__container', {
          'short-article-page-hero__container_visible':
            animationCurrentStep > 1,
          'short-article-page-hero__container_tall': animationCurrentStep > 2,
        })}
      >
        <div className="short-article-page-hero__content">
          {allStoriesLink && (
            <LinkButton
              className="short-article-page-hero__all-species-link"
              size={linkButtonSizes.small}
              arrowDirection={linkButtonArrowDirections.left}
              {...allStoriesLink}
            />
          )}
          {heading && (
            <PageHeading
              text={heading}
              className="short-article-page-hero__heading"
            />
          )}
          <div className="short-article-page-hero__text-holder">
            {introHtml && (
              <PoorText
                text={introHtml}
                className="short-article-page-hero__intro poor-text_in-short-article-page-hero"
              />
            )}
          </div>
        </div>
        {image && (
          <figure className="short-article-page-hero__image-holder">
            <Image {...image} className="image_size_short-article-page-hero" />
            {imageCaption && (
              <figcaption className="short-article-page-hero__image-caption">
                {imageCaption}
              </figcaption>
            )}
          </figure>
        )}
      </div>
    </div>
  );
};

ShortArticlePageHero.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  imageCaption: PropTypes.string,
  allStoriesLink: PropTypes.exact(Link.propTypes),
  backgroundColor: PropTypes.string,
};

export default ShortArticlePageHero;
