import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const themes = {
  dark: 'dark',
  light: 'light',
};

const LoadingIndicator = ({ className, theme = themes.dark }) => {
  return (
    <div
      className={cn('loading-indicator', className, {
        [`loading-indicator_${theme}`]: theme,
      })}
    >
      <div className="loading-indicator__icon">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

LoadingIndicator.viewModelMeta = 'ignore';

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
};

export default LoadingIndicator;
