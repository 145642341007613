import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import RichText from 'components/rich-text';
import cn from 'classnames';

const imagePositions = {
  right: 'right',
  left: 'left',
};

const ImageWithTextAndBgColorBlock = ({
  heading,
  textHtml,
  backgroundColor,
  image,
  imagePosition,
  imageCaption,
}) => {
  return (
    <div
      className={cn('block_no-gap image-with-text-and-bg-color-block', {
        [`image-with-text-and-bg-color-block_image-position_${imagePosition}`]:
          imagePosition,
      })}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="image-with-text-and-bg-color-block__container">
        <div className="image-with-text-and-bg-color-block__text-container">
          {heading && (
            <h2 className="image-with-text-and-bg-color-block__heading">
              {heading}
            </h2>
          )}
          <RichText
            text={textHtml}
            className="rich-text_in-image-with-text-and-bg-color-block"
          />
        </div>
        {image && (
          <figure className="image-with-text-and-bg-color-block__image-container">
            <Image
              {...image}
              className="image_size_image-with-text-and-bg-color-block"
            />
            {imageCaption && (
              <figcaption className="image-with-text-and-bg-color-block__image-caption">
                {imageCaption}
              </figcaption>
            )}
          </figure>
        )}
      </div>
    </div>
  );
};
ImageWithTextAndBgColorBlock.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  backgroundColor: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  imageCaption: PropTypes.string,
  imagePosition: PropTypes.oneOf(Object.values(imagePositions)).isRequired,
};

export default ImageWithTextAndBgColorBlock;
