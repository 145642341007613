import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Link from 'components/link';
import LinkButton, {
  sizes as linkButtonSizes,
  arrowDirections as linkButtonArrowDirections,
} from 'components/link-button';
import PageHeading from 'components/page-heading';
import PoorText from 'components/poor-text';
import ParallaxHeroBackground from 'components/parallax-hero-background';
import useAnimationStepSwitcher, {
  ANIMATION_STEPS,
} from 'hooks/use-animation-step-switcher';
import useBreakpoints from 'hooks/use-breakpoints';
import SpecimenPageHeroFact from './specimen-page-hero-fact';
import { calculateInterpolatedValue } from 'js/calc-utils';
import { Parallax } from 'react-parallax';
import cn from 'classnames';

const SpecimenPageHero = ({
  heading,
  introHtml,
  image,
  allSpeciesLink,
  backgroundColor,
  nutritionalInformationHtml,
  catchRangeHtml,
  seasonHtml,
  lang,
}) => {
  const { isMobile } = useBreakpoints(true);
  const animationCurrentStep = useAnimationStepSwitcher(
    ANIMATION_STEPS.twoLayersHero
  );

  return (
    <div className="specimen-page-hero">
      <ParallaxHeroBackground
        backgroundColor={backgroundColor}
        useParallaxOnMobile={false}
        className={cn('specimen-page-hero__background', {
          'specimen-page-hero__background_visible': animationCurrentStep > 0,
        })}
      />
      <div
        className={cn('specimen-page-hero__container', {
          'specimen-page-hero__container_tall': animationCurrentStep > 2,
        })}
      >
        <div
          className={cn('specimen-page-hero__content', {
            'specimen-page-hero__content_visible': animationCurrentStep > 1,
          })}
        >
          {allSpeciesLink && (
            <LinkButton
              className="specimen-page-hero__all-species-link"
              size={linkButtonSizes.small}
              arrowDirection={linkButtonArrowDirections.left}
              {...allSpeciesLink}
            />
          )}
          {heading && (
            <PageHeading
              text={heading}
              className="specimen-page-hero__heading"
            />
          )}
          <div className="specimen-page-hero__text-holder">
            {introHtml && (
              <PoorText
                text={introHtml}
                className="specimen-page-hero__intro poor-text_in-specimen-page-hero"
              />
            )}
          </div>
        </div>
        <div className="specimen-page-hero__image-holder">
          <Parallax
            style={{
              overflow: 'visible',
            }}
            renderLayer={percentage => {
              const maxPercentage = 1.3;
              const maxImageColPadding = 60;
              let imageColPadding = 0;

              if (percentage > maxPercentage) {
                imageColPadding = maxImageColPadding;
              } else {
                imageColPadding = calculateInterpolatedValue(
                  percentage,
                  maxImageColPadding,
                  maxPercentage
                );
              }

              return (
                <div
                  style={{
                    paddingLeft: isMobile
                      ? 0
                      : maxImageColPadding - imageColPadding,
                  }}
                >
                  <div
                    className={cn('specimen-page-hero__image-holder-overlay', {
                      'specimen-page-hero__image-holder-overlay_visible':
                        animationCurrentStep > 0,
                      'specimen-page-hero__image-holder-overlay_tight':
                        animationCurrentStep <= 2,
                    })}
                  >
                    <div className="specimen-page-hero__image-centered-overlay">
                      <div className="specimen-page-hero__image-centered-overlay">
                        <Image
                          {...image}
                          className="specimen-page-hero__image image_size_specimen-page-hero"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
        <div
          className={cn('specimen-page-hero__facts', {
            'specimen-page-hero__facts_visible': animationCurrentStep > 1,
          })}
        >
          {nutritionalInformationHtml && (
            <SpecimenPageHeroFact
              heading={lang.nutritionalInformationLabel}
              textHtml={nutritionalInformationHtml}
            />
          )}
          {catchRangeHtml && (
            <SpecimenPageHeroFact
              heading={lang.catchRangeLabel}
              textHtml={catchRangeHtml}
            />
          )}
          {seasonHtml && (
            <SpecimenPageHeroFact
              heading={lang.seasonLabel}
              textHtml={seasonHtml}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SpecimenPageHero.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  allSpeciesLink: PropTypes.exact(Link.propTypes),
  backgroundColor: PropTypes.string,
  nutritionalInformationHtml: PropTypes.string,
  catchRangeHtml: PropTypes.string,
  seasonHtml: PropTypes.string,
  lang: PropTypes.shape({
    nutritionalInformationLabel: PropTypes.string.isRequired,
    catchRangeLabel: PropTypes.string.isRequired,
    seasonLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default SpecimenPageHero;
