import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import RichText from 'components/rich-text';

const NotFoundPage = ({
  heading,
  textHtml,
  homePageButtonText,
  homePageUrl,
}) => {
  return (
    <div className="not-found-page">
      <div className="not-found-page__background" role="presentation"></div>
      <div className="not-found-page__container">
        <div className="not-found-page__content">
          {heading && <h1 className="not-found-page__heading">{heading}</h1>}
          {textHtml && (
            <RichText
              text={textHtml}
              className="not-found-page__text rich-text_in-not-found-page"
            />
          )}
          {homePageButtonText && homePageUrl && (
            <div className="not-found-page__btn-holder">
              <Button
                text={homePageButtonText}
                url={homePageUrl}
                className="not-found-page__btn"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

NotFoundPage.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  homePageButtonText: PropTypes.string,
  homePageUrl: PropTypes.string,
};

export default NotFoundPage;
