import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import FilterHeading from 'components/filter-heading';
import Icon from 'components/icon/icon';
import cn from 'classnames';
import idHelper from 'js/id-helper';
import SearchFormLang from 'components/models/search-form-lang/search-form-lang';

export const themes = {
  dark: 'dark',
  light: 'light',
};

export const sizes = {
  large: 'large',
  normal: 'normal',
};

const SearchForm = forwardRef(
  (
    {
      size = sizes.normal,
      theme = themes.dark,
      query,
      className,
      lang,
      searchPageUrl,
      displayTopLabel = true,
      onSearch,
    },
    ref
  ) => {
    const [value, setValue] = useState(query || '');
    const [isFocused, setIsFocused] = useState(false);
    const uniqueSearchFormId = useMemo(
      () => idHelper.generateUniqueId('search-form'),
      []
    );
    const searchInput = useRef(null);

    useEffect(() => {
      setValue(query || '');
    }, [query]);

    const isFilled = value && value.trim().length;

    const onInputChange = e => {
      const val = e.target.value;
      setValue(val);
    };

    const onInputFocus = () => {
      setIsFocused(true);
    };

    const onInputBlur = () => {
      setIsFocused(false);
    };

    const onFormSubmit = e => {
      if (onSearch) {
        e.preventDefault();
        onSearch(value);
      }
    };

    useImperativeHandle(ref, () => ({
      clearInput() {
        setValue('');
      },
      focusInput() {
        if (searchInput && searchInput.current) {
          searchInput.current.focus();
        }
      },
    }));

    return (
      <form
        className={cn('search-form', {
          [className]: className,
          'search-form_active': isFilled,
          'search-form_focused': isFocused,
        })}
        action={searchPageUrl}
        method="get"
        onSubmit={onFormSubmit}
        role="search"
        aria-label={lang.searchFormAriaLabel}
      >
        {displayTopLabel && (
          <FilterHeading
            asLabelFor={uniqueSearchFormId}
            text={lang.searchLabel}
            className="search-form__label"
          />
        )}
        <div className="search-form__container">
          <input
            id={displayTopLabel ? uniqueSearchFormId : null}
            ref={searchInput}
            value={value}
            placeholder={lang.searchHint}
            aria-label={lang.searchInputAriaLabel}
            className={cn('search-form__input', {
              [`search-form__input_size_${size}`]: size,
              [`search-form__input_theme_${theme}`]: theme,
            })}
            name="q"
            onChange={onInputChange}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
          <span className="search-form__prefix-label">{lang.searchLabel}:</span>
          <button
            className="search-form__btn"
            aria-label={lang.searchBtnAriaLabel}
          >
            <Icon
              name="search"
              className={cn('search-form__icon', {
                [`search-form__icon_theme_${theme}`]: theme,
              })}
            />
          </button>
        </div>
      </form>
    );
  }
);

SearchForm.viewModelMeta = 'ignore';

SearchForm.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string,
  searchPageUrl: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  theme: PropTypes.oneOf(Object.values(themes)),
  lang: PropTypes.shape(SearchFormLang.propTypes).isRequired,
  displayTopLabel: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default SearchForm;
