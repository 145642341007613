/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable view-models/no-unused-meta */
/* eslint-disable react/prop-types */
// TODO: remove view-models/no-unused-meta and eslint-disable react/prop-types rule when this issue https://github.com/asbjornh/view-models/issues/18 is resolved
import React from 'react';
import ButtonBase from 'components/models/button-base';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ariaAttributesModel from 'components/models/aria-attributes/aria-attributes';

const IconButton = ({
  className,
  openInNewTab = false,
  url,
  iconName,
  tabindex,
  isActive,
  onClick,
  isDisabled = false,
  ariaAttributes = {},
}) => {
  const RootTag = url ? 'a' : 'button';
  return (
    <RootTag
      href={url}
      className={cn('icon-button', {
        [className]: className,
        'icon-button_active': isActive,
      })}
      target={openInNewTab ? '_blank' : null}
      rel={openInNewTab ? 'nofollow noopener' : null}
      tabIndex={tabindex}
      onClick={onClick}
      disabled={isDisabled ? isDisabled : null}
      {...ariaAttributes}
    >
      <Icon name={iconName} className="icon-button__icon" />
    </RootTag>
  );
};

IconButton.viewModelMeta = 'ignore';

IconButton.propTypes = Object.assign({}, ButtonBase.propTypes, {
  iconName: PropTypes.string,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  ariaAttributes: PropTypes.exact(ariaAttributesModel),
});

export default IconButton;
