/* eslint-disable react/prop-types */
// TODO: remove view-models/no-unused-meta and eslint-disable react/prop-types rule when this issue https://github.com/asbjornh/view-models/issues/18 is resolved
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import cn from 'classnames';
import ButtonBase from 'components/models/button-base';
import ariaAttributesModel from 'components/models/aria-attributes/aria-attributes';

const ActionButton = ({
  onClick = () => {},
  className,
  openInNewTab = false,
  url,
  iconName,
  tabindex,
  isActive,
  isDisabled = false,
  ariaAttributes = {},
  text,
}) => {
  const RootTag = url ? 'a' : 'button';
  return (
    <RootTag
      {...ariaAttributes}
      href={url}
      className={cn('action-button', className, {
        'action-button_active': isActive,
      })}
      target={openInNewTab ? '_blank' : null}
      rel={openInNewTab ? 'nofollow noopener' : null}
      tabIndex={tabindex}
      onClick={onClick}
      disabled={isDisabled ? isDisabled : null}
      {...ariaAttributes}
    >
      <Icon name={iconName} className="action-button__icon" />
      <span className="action-button__text">{text}</span>
    </RootTag>
  );
};

ActionButton.viewModelMeta = 'ignore';

ActionButton.propTypes = Object.assign({}, ButtonBase.propTypes, {
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  ariaAttributes: PropTypes.exact(ariaAttributesModel),
});

export default ActionButton;
