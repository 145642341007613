import React from 'react';
import PropTypes from 'prop-types';
import PoorText from 'components/poor-text';
import Image from 'components/image';

const HowToStepBlock = ({ stepLabel, heading, textHtml, image }) => (
  <div className="how-to-step-block">
    <div className="how-to-step-block__image-container">
      <Image {...image} className="image_size_how-to-step-block" />
    </div>
    <div className="how-to-step-block__text-container">
      <div className="how-to-step-block__number">{stepLabel}</div>
      <strong className="how-to-step-block__heading">{heading}</strong>
      <PoorText text={textHtml} className="how-to-step-block__description" />
    </div>
  </div>
);

HowToStepBlock.propTypes = {
  stepLabel: PropTypes.string,
  heading: PropTypes.string,
  textHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
};

export default HowToStepBlock;
