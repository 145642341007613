import { useState, useEffect } from 'react';
import { getNumericCssPropertyValue } from 'js/stylesheet-utils';

export const ANIMATION_STEPS = {
  oneLayerHero: [
    '--page-hero-bg-fade-animation-duration',
    '--page-hero-content-fade-animation-duration',
  ],
  twoLayersHero: [
    '--page-hero-bg-fade-animation-duration',
    '--page-hero-content-fade-animation-duration',
  ],
  expandingLayerHero: [
    '--page-hero-bg-appearance-duration',
    '--page-hero-bg-expansion-duration',
  ],
};

const useAnimationStepSwitcher = animationSteps => {
  const [animationCurrentStep, setAnimationCurrentStep] = useState(0);

  useEffect(() => {
    setAnimationCurrentStep(1);
  }, []);

  useEffect(() => {
    const animationStepName = animationSteps[animationCurrentStep - 1];

    if (animationStepName) {
      // Avoid infinite render issue, check if step does exist
      setTimeout(() => {
        setAnimationCurrentStep(animationCurrentStep + 1);
      }, getNumericCssPropertyValue(animationStepName));
    }
  }, [animationCurrentStep]);

  return animationCurrentStep;
};

export default useAnimationStepSwitcher;
