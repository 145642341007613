import PropTypes from 'prop-types';
import FilterItem from '../filter-item/filter-item';

const Filter = {};

Filter.propTypes = {
  heading: PropTypes.string,
  allItemsLabel: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(FilterItem.propTypes)),
  name: PropTypes.string,
};

export default Filter;
