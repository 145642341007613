import React, { forwardRef, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Image from 'components/image';
import RecipeDetails, {
  sizes as recipeDetailsSizes,
} from 'components/recipe-details';
import orientations from './recipe-teaser-orientations';
import idHelper from 'js/id-helper';

const RecipeTeaser = forwardRef(
  (
    {
      heading,
      url,
      image,
      className,
      rating,
      cookingTime,
      difficultyLevel,
      tabindex,
      ariaRoledescription,
      orientation = orientations.vertical,
      headingTagLevel = 3,
      cookingTimeIconAriaLabel,
      difficultyLevelIconAriaLabel,
      ratingIconAriaLabel,
    },
    ref
  ) => {
    const imageHolderRef = useRef(null);
    const isHorizontalOrientation = orientation === orientations.horizontal;
    const HeadingTag = `h${headingTagLevel}`;

    const uniqueRecipeTeaserHeadingId = useMemo(
      () => idHelper.generateUniqueId('recipe-teaser-heading'),
      []
    );

    return (
      <a
        ref={ref}
        href={url}
        className={cn('recipe-teaser', {
          [className]: className,
          'recipe-teaser_horizontal': isHorizontalOrientation,
        })}
        tabIndex={tabindex ? tabindex : null}
        aria-labelledby={uniqueRecipeTeaserHeadingId}
        aria-roledescription={ariaRoledescription ? ariaRoledescription : null}
      >
        <div
          className={cn('recipe-teaser__image-holder', {
            'recipe-teaser__image-holder_no-image': !image,
          })}
          ref={imageHolderRef}
        >
          {image && (
            <Image
              {...image}
              fluid={isHorizontalOrientation}
              className="recipe-teaser__image image_size_recipe-teaser"
            />
          )}
        </div>

        <div className="recipe-teaser__content">
          {heading && (
            <HeadingTag
              className="recipe-teaser__heading"
              id={uniqueRecipeTeaserHeadingId}
            >
              {heading}
            </HeadingTag>
          )}
          <RecipeDetails
            size={recipeDetailsSizes.small}
            className="recipe-teaser__details"
            cookingTime={cookingTime}
            difficultyLevel={difficultyLevel}
            rating={rating}
            cookingTimeIconAriaLabel={cookingTimeIconAriaLabel}
            difficultyLevelIconAriaLabel={difficultyLevelIconAriaLabel}
            ratingIconAriaLabel={ratingIconAriaLabel}
          />
        </div>
      </a>
    );
  }
);

RecipeTeaser.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  url: PropTypes.string,
  rating: PropTypes.string,
  cookingTime: PropTypes.string,
  difficultyLevel: PropTypes.string,
  tabindex: PropTypes.number,
  ariaRoledescription: PropTypes.string,
  orientation: PropTypes.oneOf(Object.values(orientations)),
  headingTagLevel: PropTypes.number,
  cookingTimeIconAriaLabel: PropTypes.string,
  difficultyLevelIconAriaLabel: PropTypes.string,
  ratingIconAriaLabel: PropTypes.string,
};

RecipeTeaser.viewModelMeta = {
  className: 'ignore',
  tabindex: 'ignore',
  ariaRoledescription: 'ignore',
  orientation: 'ignore',
  headingTagLevel: 'ignore',
};

export default RecipeTeaser;
