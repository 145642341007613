import React from 'react';
import PropTypes from 'prop-types';

const NoResults = ({ heading, text }) => {
  return (
    <div className="no-results">
      {heading && <h2 className="no-results__heading">{heading}</h2>}
      {text && <p className="no-results__text">{text}</p>}
    </div>
  );
};

NoResults.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
};

NoResults.viewModelMeta = 'ignore';

export default NoResults;
