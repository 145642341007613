import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import Image from 'components/image';
import cn from 'classnames';
import useBreakpoints from 'hooks/use-breakpoints';
import useScrollDirection, {
  SCROLL_DIRECTIONS,
} from 'hooks/use-scroll-direction';
import LinkButton from 'components/link-button';

const SpeciesQuickOverviewBlockSpecie = ({
  link,
  image,
  bgColor,
  index,
  stayActiveOnScrollOrResize,
  setContainerBgColor,
  onSpecieActive,
}) => {
  const { isDesktopLargerThanIpad } = useBreakpoints(true);
  const [isActive, setActive] = useState(false);
  const [randomMobileImageAlign, setRandomMobileImageAlign] = useState(false);
  const specieLinkRef = useRef();
  const specieRef = useRef();
  const isDesktopRef = useRef(true);
  const scrollDirection = useScrollDirection();
  const scrollDirectionRef = useRef(scrollDirection);
  const stayActiveOnScrollOrResizeRef = useRef(stayActiveOnScrollOrResize);

  const updateIsActive = isActive => {
    setActive(image && isActive);
  };

  const onSpecieLinkMouseEnter = () => {
    if (isDesktopRef?.current) {
      updateIsActive(true);
    }
  };

  const onSpecieLinkMouseLeave = () => {
    if (isDesktopRef?.current) {
      updateIsActive(false);
    }
  };

  const onSpecieLinkFocus = () => {
    if (isDesktopRef?.current) {
      updateIsActive(true);
    }
  };

  const onSpecieLinkBlur = () => {
    if (isDesktopRef?.current) {
      updateIsActive(false);
    }
  };

  const isItemInViewportCenter = item => {
    const { top, bottom } = item.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const viewportVerticalCenterPosition =
      windowHeight /
      (scrollDirectionRef?.current === SCROLL_DIRECTIONS.UP ? 3 : 1.4);

    return (
      top <= viewportVerticalCenterPosition &&
      bottom >= viewportVerticalCenterPosition
    );
  };

  const onWindowResizeOrScroll = () => {
    if (
      isDesktopRef?.current !== undefined &&
      !isDesktopRef?.current &&
      specieRef?.current
    ) {
      if (
        stayActiveOnScrollOrResizeRef?.current !== undefined &&
        stayActiveOnScrollOrResizeRef?.current
      ) {
        updateIsActive(true);
      } else {
        updateIsActive(isItemInViewportCenter(specieRef?.current));
      }
    }
  };

  useEffect(() => {
    updateIsActive(false);
    isDesktopRef.current = isDesktopLargerThanIpad;
  }, [isDesktopLargerThanIpad]);

  useEffect(() => {
    scrollDirectionRef.current = scrollDirection;
  }, [scrollDirection]);

  useEffect(() => {
    stayActiveOnScrollOrResizeRef.current = stayActiveOnScrollOrResize;
  }, [stayActiveOnScrollOrResize]);

  useEffect(() => {
    const specieLink = specieLinkRef.current;

    if (specieLink) {
      specieLink.addEventListener('mouseenter', onSpecieLinkMouseEnter);
      specieLink.addEventListener('mouseleave', onSpecieLinkMouseLeave);
      specieLink.addEventListener('focus', onSpecieLinkFocus);
      specieLink.addEventListener('blur', onSpecieLinkBlur);
    }

    window.addEventListener('scroll', onWindowResizeOrScroll);
    window.addEventListener('resize', onWindowResizeOrScroll);

    return () => {
      window.removeEventListener('scroll', onWindowResizeOrScroll);
      window.removeEventListener('resize', onWindowResizeOrScroll);

      if (specieLink) {
        specieLink.removeEventListener('mouseenter', onSpecieLinkMouseEnter);
        specieLink.removeEventListener('mouseleave', onSpecieLinkMouseLeave);
        specieLink.removeEventListener('focus', onSpecieLinkFocus);
        specieLink.removeEventListener('blur', onSpecieLinkBlur);
      }
    };
  }, []);

  useEffect(() => {
    const mobileImageAlign = [
      'left',
      'left-with-padding',
      'right',
      'right-with-padding',
      'center',
    ];
    const randomIndex = Math.floor(Math.random() * mobileImageAlign.length);
    setRandomMobileImageAlign(mobileImageAlign[randomIndex]);
  }, []);

  useEffect(() => {
    if (image && isActive) {
      setTimeout(() => {
        setContainerBgColor(bgColor);
      }, 1);
      onSpecieActive(index);
    } else {
      setContainerBgColor(null);
    }
  }, [isActive]);

  return (
    <div className="species-quick-overview-block__specie" ref={specieRef}>
      {image && (
        <div
          className={cn('species-quick-overview-block__specie-image-holder', {
            'species-quick-overview-block__specie-image-holder_mobile-active':
              !isDesktopLargerThanIpad && isActive,
          })}
          aria-hidden={isDesktopLargerThanIpad && !isActive}
        >
          <div
            className={cn(
              'species-quick-overview-block__specie-image-wrapper',
              {
                [`species-quick-overview-block__specie-image-wrapper_mobile-align_${randomMobileImageAlign}`]:
                  randomMobileImageAlign && !isDesktopLargerThanIpad,
                'species-quick-overview-block__specie-image-wrapper_desktop-active':
                  isDesktopLargerThanIpad && isActive,
              }
            )}
          >
            <Image
              {...image}
              className="species-quick-overview-block__specie-image image_size_species-quick-overview-block"
            />
          </div>
        </div>
      )}
      <LinkButton
        ref={specieLinkRef}
        {...link}
        isActive={!isDesktopLargerThanIpad && isActive}
        className="species-quick-overview-block__specie-link"
      />
    </div>
  );
};

SpeciesQuickOverviewBlockSpecie.viewModelMeta = {
  setContainerBgColor: 'ignore',
  onSpecieActive: 'ignore',
  index: 'ignore',
  stayActiveOnScrollOrResize: 'ignore',
};

SpeciesQuickOverviewBlockSpecie.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  image: PropTypes.exact(Image.propTypes),
  bgColor: PropTypes.string.isRequired,
  stayActiveOnScrollOrResize: PropTypes.bool,
  index: PropTypes.number,
  setContainerBgColor: PropTypes.func,
  onSpecieActive: PropTypes.func,
};

export default SpeciesQuickOverviewBlockSpecie;
