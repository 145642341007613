/* eslint-disable react/prop-types */
// TODO: remove eslint-disable react/prop-types rule when this issue https://github.com/asbjornh/view-models/issues/18 is resolved
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import IconButton from 'components/icon-button';

const icons = {
  facebook: 'facebook',
  twitter: 'twitter',
  instagram: 'instagram',
  linkedin: 'linkedin',
};

const SocialLink = ({ icon, ...rest }) => {
  return <IconButton {...rest} iconName={icon} />;
};

SocialLink.propTypes = Object.assign({}, Link.propTypes, {
  icon: PropTypes.oneOf(Object.values(icons)).isRequired,
});

export default SocialLink;
