import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FilterHeading = ({ asLabelFor, text, className }) => {
  const TagName = asLabelFor ? 'label' : 'strong';

  return text ? (
    <TagName className={cn('filter-heading', className)} htmlFor={asLabelFor}>
      {text}
    </TagName>
  ) : null;
};

FilterHeading.viewModelMeta = 'ignore';

FilterHeading.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  asLabelFor: PropTypes.string,
};

export default FilterHeading;
