/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable view-models/no-unused-meta */
/* eslint-disable react/prop-types */
// TODO: remove view-models/no-unused-meta and eslint-disable react/prop-types rule when this issue https://github.com/asbjornh/view-models/issues/18 is resolved
import React from 'react';
import ButtonBase from 'components/models/button-base';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ariaAttributesModel from 'components/models/aria-attributes/aria-attributes';

const themes = {
  dark: 'dark',
  light: 'light',
};

const Button = ({
  className,
  openInNewTab = false,
  url,
  text,
  iconName,
  tabindex,
  theme,
  onClick,
  ariaAttributes = {},
}) => {
  const RootTag = url ? 'a' : 'button';
  return (
    <RootTag
      href={url}
      className={cn('button', {
        [className]: className,
        'button_theme-light': theme === themes.light,
      })}
      target={openInNewTab ? '_blank' : null}
      rel={openInNewTab ? 'nofollow noopener' : null}
      tabIndex={tabindex}
      onClick={onClick}
      {...ariaAttributes}
    >
      <span
        className={cn('button__text', {
          'button__text_theme-light': theme === themes.light,
        })}
      >
        {text}
      </span>
      {iconName && <Icon name={iconName} className="button__icon" />}
    </RootTag>
  );
};

Button.viewModelMeta = 'ignore';

Button.propTypes = Object.assign({}, ButtonBase.propTypes, {
  className: PropTypes.string,
  iconName: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  ariaAttributes: PropTypes.exact(ariaAttributesModel),
});

export default Button;
