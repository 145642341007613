export const adjustSelectWidthBasedOnSelectedOption = select => {
  if (!select) return;

  let style = window.getComputedStyle(select);
  let { fontWeight, fontSize, fontFamily, paddingRight } = style;

  let text = select.querySelector('option:checked').innerText;

  // Create hidden element to get the width of the selected option
  const span = document.createElement('span');
  span.innerHTML = text;
  span.style.fontSize = fontSize;
  span.style.fontWeight = fontWeight;
  span.style.fontFamily = fontFamily;
  span.style.paddingRight = paddingRight;
  span.style.visibility = 'hidden';

  const currentHiddenEl = select.parentNode.querySelector('span');

  if (currentHiddenEl) {
    currentHiddenEl.replaceWith(span);
  } else {
    select.parentNode.appendChild(span);
  }
};

export const calculateDropdownPanelPosition = dropdownPanel => {
  dropdownPanel.style.left = '0';
  dropdownPanel.style.right = '';

  const windowWidth = window.innerWidth;
  const elementRect = dropdownPanel.getBoundingClientRect();
  const elementLeft = elementRect.left;
  const elementWidth = elementRect.width;

  if (elementLeft + elementWidth + 20 > windowWidth) {
    dropdownPanel.style.right = '0';
    dropdownPanel.style.left = '';
  } else {
    dropdownPanel.style.right = '';
    dropdownPanel.style.left = '0';
  }
};
