import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Link from 'components/link';
import LinkButton, {
  sizes as linkButtonSizes,
  arrowDirections as linkButtonArrowDirections,
} from 'components/link-button';
import PageHeading from 'components/page-heading';
import PoorText from 'components/poor-text';
import ParallaxText from 'components/parallax-text';
import imageResizer from 'js/image-resizer';
import useBreakpoints from 'hooks/use-breakpoints';
import useAnimationStepSwitcher, {
  ANIMATION_STEPS,
} from 'hooks/use-animation-step-switcher';
import { Parallax } from 'react-parallax';
import { getNumericCssPropertyValue } from 'js/stylesheet-utils';
import cn from 'classnames';

const SCROLL_ANIMATION_PERCENTAGE = 1.1;

const LongArticlePageHero = ({
  heading,
  introHtml,
  image,
  allStoriesLink,
  backgroundColor,
}) => {
  const animationCurrentStep = useAnimationStepSwitcher(
    ANIMATION_STEPS.expandingLayerHero
  );
  const { isMobile, isTablet, isDesktopExtraLarge, isDesktopLargerThanIpad } =
    useBreakpoints(true);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const heroRef = useRef();

  useEffect(() => {
    const pageHeroMaxHeight = getNumericCssPropertyValue(
      '--page-hero-expanding-layer-max-height'
    );

    setImageHeight(isDesktopLargerThanIpad ? pageHeroMaxHeight : 600);
  }, [isDesktopLargerThanIpad]);

  useEffect(() => {
    setImageWidth(
      isDesktopExtraLarge
        ? 1800
        : imageResizer.calcImageWidth(window.innerWidth, 100)
    );
  }, [isDesktopExtraLarge]);

  return (
    <Parallax
      renderLayer={percentage => {
        return (
          <div
            className={cn('long-article-page-hero', {
              'long-article-page-hero_tight': animationCurrentStep <= 2,
              'long-article-page-hero_focused':
                animationCurrentStep > 2 &&
                percentage > SCROLL_ANIMATION_PERCENTAGE,
            })}
            ref={heroRef}
          >
            {image && (
              <div
                className={cn('long-article-page-hero__image-holder', {
                  'long-article-page-hero__image-holder_visible':
                    animationCurrentStep > 0,
                  'animated-content animated-content_delayed-appearance':
                    animationCurrentStep > 0 && (isMobile || isTablet),
                })}
              >
                <div className="long-article-page-hero__image-centered-overlay">
                  <Image
                    {...image}
                    fluid={true}
                    width={imageWidth}
                    height={imageHeight}
                    className="long-article-page-hero__image image_size_long-article-page-hero"
                  />
                </div>
              </div>
            )}
            <div className="long-article-page-hero__container">
              <div className="long-article-page-hero__content-wrapper">
                <div
                  className={cn('long-article-page-hero__content', {
                    'long-article-page-hero__content_visible':
                      animationCurrentStep > 0,
                    'long-article-page-hero__content_tall':
                      animationCurrentStep > 2,

                    'long-article-page-hero__content_hidden-by-scroll':
                      percentage > SCROLL_ANIMATION_PERCENTAGE,
                  })}
                >
                  <div
                    role="presentation"
                    style={{
                      backgroundColor: backgroundColor,
                    }}
                    className={cn('long-article-page-hero__background', {
                      'long-article-page-hero__background_visible':
                        animationCurrentStep > 0,
                    })}
                  ></div>
                  <div
                    className={cn(
                      'long-article-page-hero__content-fade-section',
                      {
                        'long-article-page-hero__content-fade-section_visible':
                          animationCurrentStep > 1,
                      }
                    )}
                  >
                    {allStoriesLink && (
                      <LinkButton
                        className="long-article-page-hero__all-stories-link"
                        size={linkButtonSizes.small}
                        arrowDirection={linkButtonArrowDirections.left}
                        {...allStoriesLink}
                      />
                    )}
                    {heading && (
                      <PageHeading
                        text={heading}
                        className="long-article-page-hero__heading"
                      />
                    )}
                    {introHtml && (
                      <PoorText
                        text={introHtml}
                        className="long-article-page-hero__intro poor-text_in-long-article-page-hero"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={cn(
                  'long-article-page-hero__text-animation-container',
                  {
                    'long-article-page-hero__text-animation-container_visible':
                      percentage > SCROLL_ANIMATION_PERCENTAGE,
                  }
                )}
              >
                <ParallaxText
                  text={heading}
                  percentage={percentage}
                  textAnimationPercentage={1}
                  textColor={backgroundColor}
                  className="long-article-page-hero__animated-heading"
                />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

LongArticlePageHero.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  allStoriesLink: PropTypes.exact(Link.propTypes),
  backgroundColor: PropTypes.string,
};

export default LongArticlePageHero;
