import PropTypes from 'prop-types';

const PagerLang = {};

PagerLang.propTypes = {
  nextLabel: PropTypes.string.isRequired,
  prevLabel: PropTypes.string.isRequired,
  pageLabel: PropTypes.string.isRequired,
  jumpBackwardAriaLabel: PropTypes.string.isRequired,
  jumpForwardAriaLabel: PropTypes.string.isRequired,
};

export default PagerLang;
