import PropTypes from 'prop-types';
import RecipeTeaser from 'components/recipe-teaser/recipe-teaser';
import Filter from 'components/models/filter/filter';

const RecipeOverviewResults = {};

RecipeOverviewResults.propTypes = {
  items: PropTypes.arrayOf(RecipeTeaser.propTypes),
  filters: PropTypes.arrayOf(PropTypes.exact(Filter.propTypes)),
  totalCount: PropTypes.number.isRequired,
  pager: PropTypes.shape({
    page: PropTypes.number,
    totalPages: PropTypes.number,
  }),
};

export default RecipeOverviewResults;
