import React from 'react';
import PropTypes from 'prop-types';
import { ANIMATION_PERCENTAGE } from '../../constants/parallax';
import cn from 'classnames';

const ParallaxText = ({
  text,
  textBackgroundColor = '#00004F',
  textColor = '#FFFFFF',
  percentage,
  textAnimationPercentage = ANIMATION_PERCENTAGE.text,
  className,
}) => {
  return text ? (
    <div
      className={cn('parallax-text', className)}
      style={{
        color: textColor,
        transition:
          percentage <= textAnimationPercentage
            ? 'left 500ms ease-out 350ms'
            : 'left 500ms ease-in 0ms',
        position: 'relative',
        bottom: 0,
        left: percentage > textAnimationPercentage ? '0%' : '-100%',
      }}
    >
      <span
        className="parallax-text__container"
        style={{
          transition:
            percentage > textAnimationPercentage
              ? 'background-position 350ms ease-in 700ms'
              : 'background-position 350ms ease-out 0s',
          backgroundSize: '100% 200%',
          backgroundPosition:
            percentage > textAnimationPercentage && '100% 100%',
          backgroundImage: `linear-gradient(${textColor}, ${textColor} 50%, ${textBackgroundColor} 50%, ${textBackgroundColor})`,
        }}
      >
        {text}
      </span>
    </div>
  ) : null;
};

ParallaxText.viewModelMeta = 'ignore';

ParallaxText.propTypes = {
  text: PropTypes.string,
  textBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  textAnimationPercentage: PropTypes.number,
  className: PropTypes.string,
};

export default ParallaxText;
