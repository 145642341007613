import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PoorText from 'components/poor-text';
import SpeciesQuickOverviewBlockSpecie from './species-quick-overview-block-specie';
import Link from 'components/link';
import LinkButton from 'components/link-button';

const SpeciesQuickOverviewBlock = ({
  heading,
  introHtml,
  species,
  remainingLinks,
  exploreAllLink,
}) => {
  const [containerBgColor, setContainerBgColor] = useState(null);
  const [lastActiveSpecieIndex, setLastActiveSpecieIndex] = useState(null);

  return (
    <div
      className="species-quick-overview-block"
      style={{
        backgroundColor: containerBgColor,
      }}
    >
      <div className="species-quick-overview-block__container">
        <div className="species-quick-overview-block__text-content">
          {heading && (
            <h2 className="species-quick-overview-block__heading">{heading}</h2>
          )}
          {introHtml && (
            <PoorText
              text={introHtml}
              className="species-quick-overview-block__intro poor-text_in-species-quick-overview-block"
            />
          )}
        </div>
        <div className="species-quick-overview-block__links-container">
          {species && (
            <div className="species-quick-overview-block__species">
              <ul className="species-quick-overview-block__species-list">
                {species.map((specie, index) => {
                  return (
                    <li
                      key={index}
                      className="species-quick-overview-block__species-list-item"
                    >
                      <SpeciesQuickOverviewBlockSpecie
                        {...specie}
                        setContainerBgColor={setContainerBgColor}
                        index={index}
                        stayActiveOnScrollOrResize={
                          (index === 0 || index === species.length - 1) &&
                          index === lastActiveSpecieIndex
                        }
                        onSpecieActive={setLastActiveSpecieIndex}
                      />
                    </li>
                  );
                })}
              </ul>
              {exploreAllLink && (
                <LinkButton
                  className="species-quick-overview-block__explore-all-link link-button_in-species-quick-overview-block"
                  {...exploreAllLink}
                />
              )}
            </div>
          )}
          {remainingLinks && (
            <ul className="species-quick-overview-block__remaining-links-list">
              {remainingLinks.map((link, index) => {
                return (
                  <li
                    key={index}
                    className="species-quick-overview-block__remaining-links-list-item"
                  >
                    <Link
                      {...link}
                      className="species-quick-overview-block__remaining-link"
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

SpeciesQuickOverviewBlock.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  species: PropTypes.arrayOf(
    PropTypes.exact(SpeciesQuickOverviewBlockSpecie.propTypes)
  ),
  remainingLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  exploreAllLink: PropTypes.exact(Link.propTypes),
};

export default SpeciesQuickOverviewBlock;
