import React from 'react';
import PropTypes from 'prop-types';
import PoorText from 'components/poor-text';

const SpecimenPageHeroFact = ({ heading, textHtml }) => (
  <div className="specimen-page-hero-fact">
    {heading && (
      <strong className="specimen-page-hero-fact__heading">{heading}</strong>
    )}
    {textHtml && <PoorText text={textHtml} />}
  </div>
);

SpecimenPageHeroFact.viewModelMeta = 'ignore';

SpecimenPageHeroFact.propTypes = {
  heading: PropTypes.string,
  textHtml: PropTypes.string,
};

export default SpecimenPageHeroFact;
