import PropTypes from 'prop-types';

const RateRecipeResult = {};

RateRecipeResult.propTypes = {
  rating: PropTypes.string,
  ratingValue: PropTypes.int,
  confirmation: PropTypes.string,
};

export default RateRecipeResult;
