import { useState, useEffect } from 'react';

export const SCROLL_DIRECTIONS = {
  UP: 'up',
  DOWN: 'down',
};

const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let prevScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY) {
        setScrollDirection(SCROLL_DIRECTIONS.DOWN);
      } else if (currentScrollY < prevScrollY) {
        setScrollDirection(SCROLL_DIRECTIONS.UP);
      }

      prevScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollDirection;
};

export default useScrollDirection;
