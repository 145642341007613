import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import cn from 'classnames';
import Icon from 'components/icon';
import articleTeaserSizes from './article-teaser-sizes';

const ArticleTeaser = ({
  image,
  heading,
  url,
  size = articleTeaserSizes.small,
  headingTagLevel = 2,
}) => {
  const HeadingTag = `h${headingTagLevel}`;
  return (
    <a
      className={cn('article-teaser', `article-teaser_size_${size}`)}
      href={url}
    >
      {image && (
        <div className="article-teaser__image-holder">
          <Image
            {...image}
            className={cn(
              'article-teaser__image',
              `image_size_article-teaser-${size}`
            )}
          />
        </div>
      )}
      <div className="article-teaser__content">
        {heading && (
          <HeadingTag className="article-teaser__heading">
            <span className="article-teaser__heading-text">{heading}</span>
          </HeadingTag>
        )}
        <Icon name="arrow-right" className="article-teaser__icon" />
      </div>
    </a>
  );
};

ArticleTeaser.viewModelMeta = {
  size: 'ignore',
  headingTagLevel: 'ignore',
};

ArticleTeaser.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  heading: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.oneOf(Object.values(articleTeaserSizes)),
  headingTagLevel: PropTypes.oneOf([2, 3]),
};

export default ArticleTeaser;
