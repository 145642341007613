import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import apiHelper from 'js/api-helper';
import formHelper from 'js/form-helper';
import useDidUpdateEffect from 'hooks/use-did-update-effect';
import useBreakpoints from 'hooks/use-breakpoints';
import PagerLang from 'components/models/pager-lang/pager-lang';
import ErrorMessage from 'components/error-message/error-message';
import StoryTeaser from 'components/story-teaser/story-teaser';
import storyTeaserSizes from 'components/story-teaser/story-teaser-sizes';
import NoResults from 'components/no-results/no-results';
import LoadingIndicator from 'components/loading-indicator/loading-indicator';
import Pager from 'components/pager/pager';
import storyTeaserOrientations from 'components/story-teaser/story-teaser-orientations';
import cn from 'classnames';

const pageNumberQueryParamName = 'page';

const getTeaserSize = (colCount, teasersCount) => {
  if (colCount > 1 && teasersCount === 1) {
    return storyTeaserSizes.medium;
  } else if (colCount > 1 && teasersCount > 1) {
    return storyTeaserSizes.small;
  }

  return storyTeaserSizes.large;
};

const StoriesOverviewList = ({ loadItemsEndpoint, lang }) => {
  const [results, setResults] = useState(null);
  const [query, setQuery] = useState(formHelper.createQuery());
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isMobile } = useBreakpoints(true);

  // eslint-disable-next-line no-unused-vars
  const updateQuery = (updateFunc = q => {}) => {
    setQuery(q => {
      q = Object.assign({}, q);
      updateFunc(q);
      return q;
    });
  };

  const loadItems = () => {
    setShowError(false);
    setIsLoading(true);
    apiHelper
      .execute(loadItemsEndpoint, query)
      .then(results => {
        setResults(results);
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const init = () => {
    updateQuery(q => formHelper.fillQueryFromPageQueryString(q));
  };

  useDidUpdateEffect(() => {
    formHelper.updatePageQueryString(query);
    loadItems();
  }, [query]);

  useDidUpdateEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [results?.pager?.page]);

  useEffect(() => {
    init();
  }, []);

  const teaserRows = useMemo(() => {
    const output = [];
    let index = 0;
    const source = results?.items;

    if (source && source.length > 0) {
      output.push([[source[index]]]);
      index++;

      while (index < source.length) {
        let pattern = [];
        const remainingItemsCount = source.length - index;

        if (remainingItemsCount < 3) {
          source[index] && pattern.push([source[index]]);
          source[index + 1] && pattern.push([source[index + 1]]);
        } else {
          if (index % 2 !== 0) {
            source[index] && pattern.push([source[index]]);
            source[index + 1] && pattern.push([source[index + 1]]);
            source[index + 2] && pattern[1].push(source[index + 2]);
          } else {
            source[index] && pattern.push([source[index]]);
            source[index + 1] && pattern[0].push(source[index + 1]);
            source[index + 2] && pattern.push([source[index + 2]]);
          }
        }

        output.push(pattern);
        index += 3;
      }
    }

    return output;
  }, [results?.items]);

  const onPageChange = page => {
    updateQuery(q => (q[pageNumberQueryParamName] = page));
  };

  return (
    <div className="stories-overview-list">
      <div className="stories-overview-list__results-container">
        {teaserRows && teaserRows.length > 0 ? (
          <div className="stories-overview-list__rows">
            {teaserRows.map((row, rowIndex) => (
              <div className="stories-overview-list__row" key={rowIndex}>
                {row &&
                  row.map((col, colIndex) => {
                    return (
                      <div
                        className={cn('stories-overview-list__col', {
                          'stories-overview-list__col_fullwidth':
                            row.length === 1,
                          'stories-overview-list__col_mobile-stretched':
                            row.length === 2 && col.length === 2,
                        })}
                        key={colIndex}
                      >
                        {col &&
                          col.map(teaser => {
                            return (
                              <div
                                className={cn(
                                  'stories-overview-list__teaser-item'
                                )}
                                key={teaser.id}
                              >
                                <StoryTeaser
                                  {...teaser}
                                  size={getTeaserSize(row.length, col.length)}
                                  orientation={
                                    isMobile && col.length > 1
                                      ? storyTeaserOrientations.horizontal
                                      : storyTeaserOrientations.vertical
                                  }
                                  className="stories-overview-list__teaser"
                                />
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            ))}
          </div>
        ) : null}
        {results?.items && results.items.length === 0 && (
          <NoResults
            heading={lang.noListResultsHeading}
            text={lang.noListResultsText}
          />
        )}
        {showError && <ErrorMessage text={lang.errorMessage} />}
        {results?.pager && (
          <Pager
            {...results?.pager}
            page={+query?.page || 1}
            className="stories-overview-list__pager"
            lang={lang.pager}
            onPageChange={onPageChange}
          />
        )}
        {isLoading && <LoadingIndicator />}
      </div>
    </div>
  );
};

StoriesOverviewList.propTypes = {
  loadItemsEndpoint: PropTypes.string.isRequired,
  lang: PropTypes.shape({
    errorMessage: PropTypes.string.isRequired,
    noListResultsHeading: PropTypes.string.isRequired,
    noListResultsText: PropTypes.string.isRequired,
    pager: PropTypes.shape(PagerLang.propTypes).isRequired,
  }),
};

export default StoriesOverviewList;
