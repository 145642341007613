import React from 'react';
import Image from 'components/image';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import storyTeaserSizes from './story-teaser-sizes';
import storyTeaserOrientations from './story-teaser-orientations';
import cn from 'classnames';

const StoryTeaser = ({
  url,
  heading,
  image,
  backgroundColor,
  categoryLabel,
  className,
  size = storyTeaserSizes.large,
  orientation = storyTeaserOrientations.vertical,
  headingTagLevel = 2,
}) => {
  const isHorizontalOrientation =
    orientation === storyTeaserOrientations.horizontal;
  const HeadingTag = `h${headingTagLevel}`;
  return (
    <a
      className={cn(`story-teaser story-teaser_size_${size}`, {
        [className]: className,
        'story-teaser_horizontal': isHorizontalOrientation,
      })}
      href={url}
    >
      <div className="story-teaser__container">
        <div
          className={cn('story-teaser__image-holder', {
            'story-teaser__image-holder_no-image': !image,
          })}
        >
          {image && (
            <Image
              {...image}
              fluid={isHorizontalOrientation}
              className={`story-teaser__image image_size_story-teaser-${size}`}
            />
          )}
        </div>
        <div
          className="story-teaser__content"
          style={{ backgroundColor: backgroundColor }}
        >
          <div className="story-teaser__content-inner">
            {categoryLabel && (
              <span className="story-teaser__category">{categoryLabel}</span>
            )}
            {heading && (
              <HeadingTag className="story-teaser__heading">
                <span className="story-teaser__heading-text">{heading}</span>
              </HeadingTag>
            )}
            <Icon name="arrow-right" className="story-teaser__icon" />
          </div>
        </div>
      </div>
    </a>
  );
};

StoryTeaser.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  backgroundColor: PropTypes.string,
  categoryLabel: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(storyTeaserSizes)),
  orientation: PropTypes.oneOf(Object.values(storyTeaserOrientations)),
  headingTagLevel: PropTypes.oneOf([2, 3]),
};

StoryTeaser.viewModelMeta = {
  size: 'ignore',
  className: 'ignore',
  orientation: 'ignore',
  headingTagLevel: 'ignore',
};

export default StoryTeaser;
