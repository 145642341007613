import React from 'react';
import PropTypes from 'prop-types';
import ShareRecipe from 'components/share-recipe';
import RateRecipe from 'components/rate-recipe';
import cn from 'classnames';

const ShareAndRateRecipePanel = ({ shareRecipe, rateRecipe, className }) => {
  return (
    <div className={cn('share-and-rate-recipe-panel', className)}>
      {shareRecipe && <ShareRecipe {...shareRecipe} />}
      {rateRecipe && <RateRecipe {...rateRecipe} />}
    </div>
  );
};

ShareAndRateRecipePanel.viewModelMeta = {
  className: 'ignore',
};

ShareAndRateRecipePanel.propTypes = {
  shareRecipe: PropTypes.exact(ShareRecipe.propTypes),
  rateRecipe: PropTypes.exact(RateRecipe.propTypes),
  className: PropTypes.string,
};

export default ShareAndRateRecipePanel;
