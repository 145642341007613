import React from 'react';
import PropTypes from 'prop-types';
import IngredientName from './ingredient-name';
import IngredientUnit from './ingredient-unit';
import IngredientAmount from './ingredient-amount';
import printOrders from './print-orders';

const Ingredient = ({ amount, printOrder, unit, name }) => {
  return (
    <React.Fragment>
      {printOrder && (
        <span className="ingredients__section-item-content">
          {printOrder === printOrders.aui && (
            <React.Fragment>
              <IngredientAmount number={amount} />
              {unit && <IngredientUnit {...unit} amount={amount} />}
              {name && <IngredientName {...name} amount={amount} />}
            </React.Fragment>
          )}
          {printOrder === printOrders.iau && (
            <React.Fragment>
              {name && <IngredientName {...name} amount={amount} />}
              <IngredientAmount number={amount} />
              {unit && <IngredientUnit {...unit} amount={amount} />}
            </React.Fragment>
          )}
          {printOrder === printOrders.iua && (
            <React.Fragment>
              {name && <IngredientName {...name} amount={amount} />}
              {unit && <IngredientUnit {...unit} amount={amount} />}
              <IngredientAmount number={amount} />
            </React.Fragment>
          )}
        </span>
      )}
    </React.Fragment>
  );
};

Ingredient.propTypes = {
  printOrder: PropTypes.oneOf(Object.values(printOrders)),
  amount: PropTypes.number,
  unit: PropTypes.shape({
    plural: PropTypes.string,
    singular: PropTypes.string,
  }),
  name: PropTypes.shape({
    plural: PropTypes.string,
    singular: PropTypes.string,
  }),
};

Ingredient.viewModelMeta = 'ignore';

export default Ingredient;
