import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FilterItem from 'components/models/filter-item/filter-item';
import ToggleButton from 'components/toggle-button/toggle-button';
import cn from 'classnames';

const ToggleFilter = ({
  name,
  items,
  isDisabled,
  // eslint-disable-next-line no-unused-vars
  onChange = ({ name, items }) => {},
}) => {
  const [filterItems, setFilterItems] = useState(items);

  useEffect(() => {
    setFilterItems(items);
  }, [items]);

  const onFilterClick = clickedItem => {
    const updatedItems = filterItems.map(item => ({
      ...item,
      selected:
        item.value === clickedItem.value ? !item.selected : item.selected,
    }));

    setFilterItems(updatedItems);
    onChange({ name: name, items: updatedItems });
  };

  return filterItems && filterItems.length > 0 ? (
    <ul
      className={cn('toggle-filter', {
        'toggle-filter_disabled': isDisabled,
      })}
    >
      {filterItems.map(item => (
        <li
          key={item.value}
          className={cn('toggle-filter__item', {
            'toggle-filter__item_selected': item.selected,
          })}
        >
          <ToggleButton
            isActive={item.selected}
            text={item.text}
            onClick={() => onFilterClick(item)}
          />
        </li>
      ))}
    </ul>
  ) : null;
};

ToggleFilter.propTypes = {
  name: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(FilterItem.propTypes)),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

ToggleFilter.viewModelMeta = {
  onChange: 'ignore',
  isDisabled: 'ignore',
};

export default ToggleFilter;
