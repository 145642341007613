import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionButton from 'components/action-button';
import cn from 'classnames';
import Icon from 'components/icon';

const ShareRecipe = ({ heading, lang }) => {
  const [showUrlCopiedConfirmation, setShowUrlCopiedConfirmation] =
    useState(false);
  const [isNativeShareSupported, setIsNativeShareSupported] = useState(false);

  const onCopyUrlButtonClick = () => {
    const currentPageURL = window.location.href;

    try {
      navigator.clipboard.writeText(currentPageURL);
      setShowUrlCopiedConfirmation(true);
      setTimeout(() => {
        setShowUrlCopiedConfirmation(false);
      }, 10000);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy URL:', error);
    }
  };

  const onShareButtonClick = () => {
    if (navigator.share) {
      try {
        navigator.share({
          title: document.title,
          url: window.location.href,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error sharing:', error);
      }
    }
  };
  React.useEffect(() => {
    if (navigator.share) {
      setIsNativeShareSupported(true);
    }
  }, []);

  return (
    <div className="share-recipe">
      {heading && <h2 className="share-recipe__heading">{heading}</h2>}
      <div className="share-recipe__buttons">
        {isNativeShareSupported && (
          <ActionButton
            onClick={onShareButtonClick}
            text={lang.shareButtonText}
            iconName="share"
            className="share-recipe__button share-recipe__button_share"
          />
        )}
        <div className="share-recipe__copy-button-container">
          <ActionButton
            onClick={onCopyUrlButtonClick}
            text={lang.copyUrlButtonText}
            iconName="copy"
            className="share-recipe__button share-recipe__button_copy"
          />
          <div
            role="status"
            className={cn('share-recipe__copy-confirmation', {
              'share-recipe__copy-confirmation_active':
                showUrlCopiedConfirmation,
            })}
          >
            <div className="share-recipe__copy-confirmation-content">
              {showUrlCopiedConfirmation && (
                <>
                  <strong className="share-recipe__copy-confirmation-heading">
                    <Icon
                      name="check"
                      className="share-recipe__copy-confirmation-icon"
                    />
                    <span className="share-recipe__copy-confirmation-heading-text">
                      {lang.copyUrlConfirmationHeading}
                    </span>
                  </strong>
                  <div className="share-recipe__copy-confirmation-text">
                    {lang.copyUrlConfirmationText}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShareRecipe.propTypes = {
  heading: PropTypes.string,
  lang: PropTypes.shape({
    copyUrlButtonText: PropTypes.string.isRequired,
    shareButtonText: PropTypes.string.isRequired,
    copyUrlConfirmationHeading: PropTypes.string.isRequired,
    copyUrlConfirmationText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShareRecipe;
