/* eslint-disable react/prop-types */
// TODO: remove view-models/no-unused-meta and eslint-disable react/prop-types rule when this issue https://github.com/asbjornh/view-models/issues/18 is resolved
import React, { useEffect, useMemo, useState, useRef } from 'react';
import Filter from 'components/models/filter';
import ToggleButton from 'components/toggle-button/toggle-button';
import cn from 'classnames';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import idHelper from 'js/id-helper';
import useClickOutside from 'hooks/use-click-outside';
import useEscape from 'hooks/use-escape';
import {
  adjustSelectWidthBasedOnSelectedOption,
  calculateDropdownPanelPosition,
} from 'js/custom-dropdown-utils';

const KEY_NAMES = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  TAB: 'Tab',
};

const RecipeFilter = ({
  heading,
  allItemsLabel,
  items,
  name,
  // eslint-disable-next-line no-unused-vars
  onChange = ({ name, items }) => {},
}) => {
  const [currentItems, setCurrentItems] = useState(items);
  const [isActive, setIsActive] = useState(false);
  const [allItem, setAllItem] = useState(
    allItemsLabel ? { text: allItemsLabel, value: 'all', selected: true } : null
  );
  const [focusedButtonIndex, setFocusedButtonIndex] = useState(0);
  const recipePanelRef = useRef(null);
  const recipeFilterRef = useRef(null);
  const mobileSelectorRef = useRef(null);
  const focusedButtonIndexRef = useRef(focusedButtonIndex);

  const closeFilter = () => setIsActive(false);

  useEscape(closeFilter);
  useClickOutside(recipeFilterRef, closeFilter);

  const uniqueRecipeFilterPanelId = useMemo(
    () => idHelper.generateUniqueId('recipe-filter-panel'),
    []
  );

  const filterPanelColumnCount = useMemo(() => {
    let columnCount = 1;
    const itemCount = currentItems?.length;

    switch (true) {
      case itemCount > 23:
        columnCount = 4;
        break;
      case itemCount > 15:
        columnCount = 3;
        break;
      case itemCount > 7:
        columnCount = 2;
        break;
      default:
        break;
    }

    return columnCount;
  }, [currentItems?.length]);

  const handleKeyDown = e => {
    const focusedButtonIndexRefCurrent = focusedButtonIndexRef.current;

    if (e.key === KEY_NAMES.ARROW_UP || e.key === KEY_NAMES.ARROW_DOWN) {
      e.preventDefault();
    }
    if (isActive) {
      if (e.key === KEY_NAMES.ARROW_UP && focusedButtonIndexRefCurrent > 0) {
        setFocusedButtonIndex(focusedButtonIndexRefCurrent - 1);
      } else if (
        e.key === KEY_NAMES.ARROW_DOWN &&
        focusedButtonIndexRefCurrent < currentItems.length
      ) {
        setFocusedButtonIndex(focusedButtonIndexRefCurrent + 1);
      } else if (e.key === KEY_NAMES.TAB) {
        setIsActive(false);
      }
    }
  };

  useEffect(() => {
    const filterItems = recipePanelRef.current.querySelectorAll(
      '.recipe-filter__option'
    );

    if (focusedButtonIndexRef && focusedButtonIndexRef.current !== undefined) {
      focusedButtonIndexRef.current = focusedButtonIndex;

      if (filterItems && filterItems.length > 0) {
        filterItems[focusedButtonIndex].focus();
      }
    }
  }, [focusedButtonIndex, focusedButtonIndexRef]);

  useEffect(() => {
    const filterItems = recipePanelRef.current.querySelectorAll(
      '.recipe-filter__option'
    );

    if (isActive) {
      document.addEventListener('keydown', handleKeyDown);

      if (filterItems && filterItems.length > 0) {
        filterItems[0].focus();
      }
    } else {
      document.removeEventListener('keydown', handleKeyDown);
      setFocusedButtonIndex(0);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isActive]);

  useEffect(() => {
    if (isActive && filterPanelColumnCount < 4) {
      calculateDropdownPanelPosition(recipePanelRef?.current);
    }
  }, [isActive, filterPanelColumnCount]);

  const handleWindowResize = debounce(() => {
    if (filterPanelColumnCount < 4) {
      calculateDropdownPanelPosition(recipePanelRef?.current);
    }
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setCurrentItems(items);
  }, [items]);

  useEffect(() => {
    if (mobileSelectorRef?.current) {
      adjustSelectWidthBasedOnSelectedOption(mobileSelectorRef.current);
    }
  }, [mobileSelectorRef]);

  useEffect(() => {
    setAllItem(allItem =>
      allItem
        ? {
            ...allItem,
            selected: !currentItems.some(item => item.selected),
          }
        : null
    );
    if (currentItems !== items && currentItems.length > 0) {
      onChange({ name: name, items: currentItems });
    }
  }, [currentItems]);

  const updateItem = targetItem => {
    closeFilter();
    setCurrentItems(currentItems =>
      currentItems.map(item => ({
        ...item,
        selected: targetItem === item ? !item.selected : false,
      }))
    );
  };

  const updateAllItems = () => {
    closeFilter();
    setCurrentItems(currentItems =>
      currentItems.map(item => ({ ...item, selected: false }))
    );
  };

  const getToggleButtonText = () => {
    let buttonText = allItemsLabel;
    const matchedFilterItem = currentItems
      ? currentItems.find(item => item.selected)
      : null;

    if (currentItems && currentItems.length > 0 && matchedFilterItem) {
      buttonText = matchedFilterItem.text;
    }
    return buttonText;
  };

  const onMobileSelectorChange = e => {
    adjustSelectWidthBasedOnSelectedOption(mobileSelectorRef?.current);
    updateItem(currentItems.find(item => item.value === e.target.value));
  };

  return (
    <div
      className={cn(
        `recipe-filter ${
          filterPanelColumnCount > 1
            ? `recipe-filter_${filterPanelColumnCount}-columns`
            : ''
        }`
      )}
      ref={recipeFilterRef}
    >
      {heading && <span className="recipe-filter__heading">{heading}</span>}
      {currentItems && currentItems.length > 0 && (
        <div className="recipe-filter__mobile-selector-holder">
          <select
            className="recipe-filter__mobile-selector"
            value={currentItems.find(item => item.selected)?.value}
            onChange={onMobileSelectorChange}
            ref={mobileSelectorRef}
          >
            {currentItems.length > 0 && allItem && (
              <option value={allItem.value}>{allItem.text}</option>
            )}
            {currentItems.map(option => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
      )}
      <ToggleButton
        text={getToggleButtonText()}
        onClick={() => setIsActive(!isActive)}
        isActive={isActive}
        iconName="chevron-down"
        activeIconName="chevron-up"
        className="recipe-filter__desktop-selector"
        ariaAttributes={{
          'aria-expanded': isActive,
          'aria-controls': uniqueRecipeFilterPanelId,
        }}
      />
      <div
        className={cn('recipe-filter__panel', {
          'recipe-filter__panel_opened': isActive,
        })}
        ref={recipePanelRef}
        id={uniqueRecipeFilterPanelId}
        {...{ 'aria-hidden': !isActive }}
      >
        {currentItems.length > 0 && allItem && (
          <button
            className={cn('recipe-filter__option', {
              ['recipe-filter__option_selected']: allItem.selected,
            })}
            key={allItem.value}
            onClick={() => updateAllItems(allItem.selected)}
          >
            {allItem.text}
          </button>
        )}
        {currentItems.map(item => {
          return (
            <button
              className={cn('recipe-filter__option', {
                ['recipe-filter__option_selected']: item.selected,
              })}
              key={item.value}
              onClick={() => updateItem(item)}
            >
              {item.text}
            </button>
          );
        })}
      </div>
    </div>
  );
};

RecipeFilter.propTypes = Object.assign({}, Filter.propTypes, {
  onChange: PropTypes.func,
});

RecipeFilter.viewModelMeta = 'ignore';

export default RecipeFilter;
