import React from 'react';
import Image from 'components/image';
import Icon from 'components/icon';
import Link from 'components/link';
import PropTypes from 'prop-types';
import cn from 'classnames';

const PreparationBlock = ({ link, heading, image }) => {
  const LinkHeadingTag = `h${heading ? 3 : 2}`;
  return (
    <div className="preparation-block">
      <div className="preparation-block__container">
        {heading && <h2 className="preparation-block__heading">{heading}</h2>}
        <a
          href={link.url}
          className="preparation-block__link"
          target={link.openInNewTab ? '_blank' : null}
          rel={link.openInNewTab ? 'nofollow noopener noreferrer' : null}
        >
          <div className="preparation-block__wrapper">
            <div
              className={cn('preparation-block__image-holder', {
                'preparation-block__image-holder_no-image': !image,
              })}
            >
              {image && (
                <Image
                  {...image}
                  //fluid={isHorizontalOrientation}
                  className="preparation-block__image image_size_preparation-block"
                />
              )}
            </div>
            <div className="preparation-block__content">
              {link.text && (
                <LinkHeadingTag className="preparation-block__link-heading">
                  <span className="preparation-block__link-heading-text">
                    {link.text}
                  </span>
                </LinkHeadingTag>
              )}
              <Icon name="arrow-right" className="preparation-block__icon" />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

PreparationBlock.propTypes = {
  link: PropTypes.exact(Link.propTypes).isRequired,
  heading: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
};

export default PreparationBlock;
