import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo';
import Image from 'components/image';
import stringHelper from 'js/string-helper';
import cn from 'classnames';

const HowToBuyBlock = ({ specimenName, image, howToBuyLogo, lang }) => (
  <div className="how-to-buy-block">
    <div className="how-to-buy-block__container">
      <div className="how-to-buy-block__image-container">
        <div
          className={cn('how-to-buy-block__image-holder', {
            'how-to-buy-block__image-holder_no-image': !image,
          })}
        >
          {image && (
            <Image {...image} className="image_size_how-to-buy-block" />
          )}
        </div>
        {howToBuyLogo && (
          <Logo
            {...howToBuyLogo}
            className="how-to-buy-block__logo"
            imageClass="image_size_how-to-buy-block-logo"
          />
        )}
      </div>
      <div className="how-to-buy-block__content">
        <h2 className="how-to-buy-block__heading">{lang.howToBuyHeading}</h2>
        <p className="how-to-buy-block__text">
          {stringHelper.format(lang.howToBuyText, specimenName)}
        </p>
      </div>
    </div>
  </div>
);

HowToBuyBlock.propTypes = {
  specimenName: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  howToBuyLogo: PropTypes.exact(Logo.propTypes),
  lang: PropTypes.shape({
    howToBuyText: PropTypes.string.isRequired,
    howToBuyHeading: PropTypes.string.isRequired,
  }).isRequired,
};

export default HowToBuyBlock;
