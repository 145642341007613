import React from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-parallax';
import { calculateInterpolatedValue } from 'js/calc-utils';
import useBreakpoints from 'hooks/use-breakpoints';
import cn from 'classnames';

const ParallaxHeroBackground = ({
  backgroundColor,
  useParallaxOnMobile = true,
  className,
}) => {
  const { isMobile } = useBreakpoints(true);

  return (
    <Parallax
      style={{
        position: 'absolute',
        overflow: 'visible',
      }}
      className="parallax-hero-background"
      strength={200}
      renderLayer={percentage => {
        const maxPercentage = 1.3;
        const maxBorderWidth = isMobile ? 35 : 60;
        let boxShadowWidth = 0;

        if ((isMobile && useParallaxOnMobile) || !isMobile) {
          if (percentage > maxPercentage) {
            boxShadowWidth = maxBorderWidth;
          } else {
            boxShadowWidth = calculateInterpolatedValue(
              percentage,
              maxBorderWidth,
              maxPercentage
            );
          }
        }

        return (
          <div
            role="presentation"
            style={{
              backgroundColor: backgroundColor,
              boxShadow: `0 0 0 ${boxShadowWidth}px ${backgroundColor}`,
            }}
            className={cn('parallax-hero-background__inner', className)}
          ></div>
        );
      }}
    ></Parallax>
  );
};

ParallaxHeroBackground.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  useParallaxOnMobile: PropTypes.bool,
  className: PropTypes.string,
};

ParallaxHeroBackground.viewModelMeta = 'ignore';

export default ParallaxHeroBackground;
