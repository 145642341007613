import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconLabel from 'components/icon-label';

export const sizes = {
  normal: 'normal',
  small: 'small',
};

const RecipeDetails = ({
  className,
  rating,
  size,
  cookingTime,
  difficultyLevel,
  cookingTimeIconAriaLabel,
  difficultyLevelIconAriaLabel,
  ratingIconAriaLabel,
  useFocusableDetails = false,
}) => {
  const commonIconLabelProps = {
    className: 'recipe-details__item',
    size: size,
    ...(useFocusableDetails && { tabIndex: 0 }),
  };

  return (
    <div
      className={cn('recipe-details', className, {
        'recipe-details_small': size === sizes.small,
      })}
    >
      {cookingTime && (
        <IconLabel
          icon="cooking-time"
          iconAriaLabel={cookingTimeIconAriaLabel}
          text={cookingTime}
          {...commonIconLabelProps}
        />
      )}
      {difficultyLevel && (
        <IconLabel
          icon="difficulty-level"
          iconAriaLabel={difficultyLevelIconAriaLabel}
          text={difficultyLevel}
          {...commonIconLabelProps}
        />
      )}
      {rating && (
        <IconLabel
          icon="rating"
          iconAriaLabel={ratingIconAriaLabel}
          text={rating}
          {...commonIconLabelProps}
        />
      )}
    </div>
  );
};

RecipeDetails.viewModelMeta = 'ignore';

RecipeDetails.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  rating: PropTypes.string,
  cookingTime: PropTypes.string,
  difficultyLevel: PropTypes.string,
  cookingTimeIconAriaLabel: PropTypes.string,
  difficultyLevelIconAriaLabel: PropTypes.string,
  ratingIconAriaLabel: PropTypes.string,
  useFocusableDetails: PropTypes.bool,
};

export default RecipeDetails;
