import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import StoryTeaser from 'components/story-teaser/story-teaser';
import LinkButton, {
  sizes as linkButtonSizes,
  arrowDirections as linkButtonArrowDirections,
} from 'components/link-button';
import storyTeaserSizes from 'components/story-teaser/story-teaser-sizes';
import cn from 'classnames';

const StoryTeasersBlock = ({ heading, story1, story2, showMoreLink }) => {
  const storySize =
    story1 && story2 ? storyTeaserSizes.medium : storyTeaserSizes.large;

  const storyHeadingTagLevel = heading ? 3 : 2;

  return (
    <div className="story-teasers-block">
      <div
        className={cn('story-teasers-block__container', {
          'story-teasers-block__container_2teasers': story1 && story2,
        })}
      >
        {heading && <h2 className="story-teasers-block__heading">{heading}</h2>}
        <div className="story-teasers-block__teasers-wrapper">
          {story1 && (
            <StoryTeaser
              {...story1}
              size={storySize}
              headingTagLevel={storyHeadingTagLevel}
            />
          )}
          {story2 && (
            <StoryTeaser
              {...story2}
              size={storySize}
              headingTagLevel={storyHeadingTagLevel}
            />
          )}
        </div>
        {showMoreLink && (
          <LinkButton
            className="story-teasers-block__show-more-link"
            size={linkButtonSizes.small}
            arrowDirection={linkButtonArrowDirections.right}
            {...showMoreLink}
          />
        )}
      </div>
    </div>
  );
};

StoryTeasersBlock.propTypes = {
  heading: PropTypes.string,
  story1: PropTypes.exact(StoryTeaser.propTypes),
  story2: PropTypes.exact(StoryTeaser.propTypes),
  showMoreLink: PropTypes.exact(Link.propTypes),
};

export default StoryTeasersBlock;
