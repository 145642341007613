import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimplePageHero from 'components/simple-page-hero/simple-page-hero';
import ToggleFilter from 'components/toggle-filter/toggle-filter';
import ErrorMessage from 'components/error-message/error-message';
import NoResults from 'components/no-results/no-results';
import Pager from 'components/pager/pager';
import SearchForm, {
  sizes as searchFormSizes,
} from 'components/search-form/search-form';
import LoadingIndicator from 'components/loading-indicator/loading-indicator';
import apiHelper from 'js/api-helper';
import formHelper from 'js/form-helper';
import stringHelper from 'js/string-helper';
import useDidUpdateEffect from 'hooks/use-did-update-effect';
import useBreakpoints from 'hooks/use-breakpoints';
import recipeTeaserOrientations from 'components/recipe-teaser/recipe-teaser-orientations';
import PagerLang from 'components/models/pager-lang/pager-lang';
import SearchFormLang from 'components/models/search-form-lang/search-form-lang';

import * as Components from '../../app.components';

const searchQueryParamName = 'q';
const pageNumberQueryParamName = 'page';

const SearchPage = ({ heading, searchEndpoint, lang }) => {
  const [results, setResults] = useState(null);
  const [query, setQuery] = useState(formHelper.createQuery());
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { isMobile } = useBreakpoints(true);

  // eslint-disable-next-line no-unused-vars
  const updateQuery = (updateFunc = q => {}) => {
    setQuery(q => {
      q = Object.assign({}, q);
      updateFunc(q);
      return q;
    });
  };

  const loadItems = () => {
    setShowError(false);
    setIsLoading(true);
    apiHelper
      .execute(searchEndpoint, query)
      .then(results => {
        formHelper.updateFilterFromQuery(
          query,
          results?.pageTypeFilter?.name,
          results?.pageTypeFilter?.items
        );
        setResults(results);
      })
      .catch(() => {
        setShowError(true);
        setResults(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const init = () => {
    updateQuery(q => formHelper.fillQueryFromPageQueryString(q));
  };

  useDidUpdateEffect(() => {
    formHelper.updatePageQueryString(query);
    loadItems();
  }, [query]);

  useDidUpdateEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [results?.pager?.page]);

  useEffect(() => {
    init();
  }, []);

  const onSearch = val => {
    updateQuery(q => {
      q[pageNumberQueryParamName] = 1;

      return formHelper.setToQueryTextInputValue(q, {
        name: searchQueryParamName,
        value: val,
      });
    });
  };

  const onPageTypeFilterChange = e => {
    updateQuery(q => {
      q[pageNumberQueryParamName] = 1;

      return formHelper.updateQueryFromFilter(q, e.name, e.items);
    });
  };

  const onPageChange = page => {
    updateQuery(q => (q[pageNumberQueryParamName] = page));
  };

  return (
    <div className="search-page">
      <SimplePageHero heading={heading} backgroundColor="#BDD5DE">
        <div className="search-page__settings-panel">
          <div className="search-page__search-form-holder">
            <SearchForm
              displayTopLabel={false}
              lang={lang.searchForm}
              query={query[searchQueryParamName]}
              size={searchFormSizes.large}
              className="search-page__search-form search-form_in-search-page"
              onSearch={onSearch}
            />
            {results && !isLoading && (
              <p
                className="search-page__hits-text"
                role="region"
                aria-live="polite"
              >
                {query.q
                  ? stringHelper.format(
                      lang.searchHitsText,
                      query.q,
                      results.totalCount
                    )
                  : lang.emptySearchTermText}
              </p>
            )}
          </div>

          {results?.pageTypeFilter && (
            <div className="search-page__filter">
              <ToggleFilter
                {...results.pageTypeFilter}
                isDisabled={isLoading}
                onChange={onPageTypeFilterChange}
              />
            </div>
          )}
        </div>
      </SimplePageHero>
      <div className="search-page__results-container animated-content animated-content_delayed-appearance">
        {results?.hits && results.hits.length > 0 ? (
          <ul className="search-page__teasers">
            {results.hits.map(item => {
              const Block = Components[item.componentName];
              if (!Block) {
                // eslint-disable-next-line no-console
                console.error(
                  `Components.${item.componentName} is not defined`
                );
                return null;
              }
              return (
                <li key={item.id} className="search-page__teaser-item">
                  <Block
                    {...item}
                    className="search-page__teaser"
                    // orientation and headingTagLevel properties handled only by the RecipeTeaser component, it does not affect other teasers
                    headingTagLevel={2}
                    orientation={
                      isMobile
                        ? recipeTeaserOrientations.horizontal
                        : recipeTeaserOrientations.vertical
                    }
                  />
                </li>
              );
            })}
          </ul>
        ) : null}
        {results?.hits && results.hits.length === 0 && (
          <NoResults
            heading={lang.noResultsHeading}
            text={lang.noResultsText}
          />
        )}
        {showError && <ErrorMessage text={lang.errorMessage} />}
        {results?.pager && (
          <Pager
            {...results?.pager}
            page={+query?.page || 1}
            className="search-page__pager"
            lang={lang.pager}
            onPageChange={onPageChange}
          />
        )}
        {isLoading && <LoadingIndicator />}
      </div>
    </div>
  );
};

SearchPage.propTypes = {
  heading: PropTypes.string,
  searchEndpoint: PropTypes.string.isRequired,
  lang: PropTypes.shape({
    errorMessage: PropTypes.string.isRequired,
    noResultsHeading: PropTypes.string.isRequired,
    noResultsText: PropTypes.string.isRequired,
    searchHitsText: PropTypes.string.isRequired,
    emptySearchTermText: PropTypes.string.isRequired,
    pager: PropTypes.shape(PagerLang.propTypes).isRequired,
    searchForm: PropTypes.shape(SearchFormLang.propTypes).isRequired,
  }),
};

export default SearchPage;
