import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
const sizes = {
  normal: 'normal',
  small: 'small',
};
// NOTE: Could be rendered on BE side as static html.
const PageHeading = ({ text, className, size }) => {
  return (
    <h1
      className={cn('page-heading', className, {
        'page-heading_small': size === sizes.small,
      })}
    >
      {text}
    </h1>
  );
};

PageHeading.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
};

PageHeading.viewModelMeta = 'ignore';

export default PageHeading;
