import PropTypes from 'prop-types';

const LinkBase = {};

LinkBase.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  openInNewTab: PropTypes.bool,
};

export default LinkBase;
