import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const linePositions = {
  right: 'right',
  left: 'left',
};

const OriginMatters = ({
  className,
  text,
  linePosition = 'left',
  calculatedLineOffset,
}) => {
  return (
    <div
      className={cn('origin-matters', className, {
        [`origin-matters_line-position_${linePosition}`]: linePosition,
      })}
    >
      <div
        className="origin-matters__line"
        style={{
          ...(linePosition === linePositions.left
            ? { left: calculatedLineOffset }
            : { right: calculatedLineOffset }),
        }}
      ></div>
      <span className="origin-matters__text">{text}</span>
    </div>
  );
};

OriginMatters.viewModelMeta = 'ignore';

OriginMatters.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  calculatedLineOffset: PropTypes.number,
  linePosition: PropTypes.oneOf(Object.values(linePositions)),
};

export default OriginMatters;
