import React from 'react';
import Image from 'components/image';
import Logo from 'components/logo';
import Link from 'components/link';
import Button from 'components/button';
import PoorText from 'components/poor-text';
import OriginMatters from 'components/origin-matters';
import stringHelper from 'js/string-helper';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SpecimenTeaserBlock = ({
  className,
  heading,
  image,
  nutritionalInformationHtml,
  seasonHtml,
  howToBuyLogo,
  lang,
  learnMoreLink,
}) => {
  return (
    <div className={cn('specimen-teaser-block', className)}>
      <div className="specimen-teaser-block__container">
        <div className="specimen-teaser-block__image-holder">
          {image && (
            <Image
              {...image}
              className="specimen-teaser-block__image image_size_specimen-teaser-block"
            />
          )}
          <OriginMatters
            text={lang.originMattersLabel}
            className="specimen-teaser-block__slogan"
          />
        </div>
        <div className="specimen-teaser-block__content">
          <span className="specimen-teaser-block__category">
            {lang.seafoodUsedInRecipeLabel}
          </span>
          {heading && (
            <h2 className="specimen-teaser-block__heading">{heading}</h2>
          )}
          <div className="specimen-teaser-block__facts">
            {nutritionalInformationHtml && (
              <div className="specimen-teaser-block__fact">
                <strong className="specimen-teaser-block__fact-heading">
                  {lang.nutritionalInformationLabel}
                </strong>
                {nutritionalInformationHtml && (
                  <PoorText text={nutritionalInformationHtml} />
                )}
              </div>
            )}
            {seasonHtml && (
              <div className="specimen-teaser-block__fact">
                <strong className="specimen-teaser-block__fact-heading">
                  {lang.seasonLabel}
                </strong>
                {seasonHtml && <PoorText text={seasonHtml} />}
              </div>
            )}
            <div className="specimen-teaser-block__fact">
              <strong className="specimen-teaser-block__fact-heading">
                {stringHelper.format(lang.howToBuyLabel, heading)}
              </strong>
              <div className="specimen-teaser-block__fact-logo-content">
                {howToBuyLogo && (
                  <Logo
                    {...howToBuyLogo}
                    className="specimen-teaser-block__logo"
                    imageClass="image_size_specimen-teaser-block-logo"
                  />
                )}
                <p>{lang.howToBuyText}</p>
              </div>
            </div>
          </div>
          {learnMoreLink && (
            <div className="specimen-teaser-block__button-holder">
              <Button
                className="specimen-teaser-block__button"
                {...learnMoreLink}
              />
            </div>
          )}
          <OriginMatters
            text={lang.originMattersLabel}
            className="specimen-teaser-block__slogan specimen-teaser-block__slogan_mobile"
          />
        </div>
      </div>
    </div>
  );
};

SpecimenTeaserBlock.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  nutritionalInformationHtml: PropTypes.string,
  seasonHtml: PropTypes.string,
  howToBuyLogo: PropTypes.exact(Logo.propTypes),
  lang: PropTypes.shape({
    nutritionalInformationLabel: PropTypes.string.isRequired,
    seasonLabel: PropTypes.string.isRequired,
    originMattersLabel: PropTypes.string.isRequired,
    seafoodUsedInRecipeLabel: PropTypes.string.isRequired,
    howToBuyText: PropTypes.string.isRequired,
    howToBuyLabel: PropTypes.string.isRequired,
  }).isRequired,
  learnMoreLink: PropTypes.exact(Link.propTypes),
};

SpecimenTeaserBlock.viewModelMeta = {
  className: 'ignore',
};

export default SpecimenTeaserBlock;
