import PropTypes from 'prop-types';
import StoryTeaser from 'components/story-teaser/story-teaser';

const StoriesOverviewResults = {};

StoriesOverviewResults.propTypes = {
  items: PropTypes.arrayOf(StoryTeaser.propTypes),
  pager: PropTypes.shape({
    page: PropTypes.number,
    totalPages: PropTypes.number,
  }),
};

export default StoriesOverviewResults;
