import PubSub from 'pubsub-js';

const ratingTopic = 'NEWRATING';

const recipeMessageBus = {
  subscribeToNewRating: callback => PubSub.subscribe(ratingTopic, callback),
  publishNewRating: newRating => PubSub.publish(ratingTopic, newRating),
  unsubscribeFromNewRating: token => PubSub.unsubscribe(token),
};

export default recipeMessageBus;
