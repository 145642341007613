import PropTypes from 'prop-types';

const FilterItem = {};

FilterItem.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

export default FilterItem;
