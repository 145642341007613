import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import PoorText from 'components/poor-text';
import PageHeading from 'components/page-heading';
import ParallaxHeroBackground from 'components/parallax-hero-background';
import OriginMatters, {
  linePositions as OriginMattersLinePositions,
} from 'components/origin-matters';
import useAnimationStepSwitcher, {
  ANIMATION_STEPS,
} from 'hooks/use-animation-step-switcher';
import useBreakpoints from 'hooks/use-breakpoints';
import { calculateInterpolatedValue } from 'js/calc-utils';
import { Parallax } from 'react-parallax';
import cn from 'classnames';

const FrontPageHero = ({ heading, introHtml, image, lang }) => {
  const { isMobile } = useBreakpoints(true);
  const animationCurrentStep = useAnimationStepSwitcher(
    ANIMATION_STEPS.twoLayersHero
  );

  return (
    <div className="front-page-hero">
      <ParallaxHeroBackground
        backgroundColor="#EEE4DD"
        className={cn('front-page-hero__background', {
          'front-page-hero__background_visible': animationCurrentStep > 0,
        })}
      />
      <div
        className={cn('front-page-hero__container', {
          'front-page-hero__container_tall': animationCurrentStep > 2,
        })}
      >
        <div
          className={cn('front-page-hero__content', {
            'front-page-hero__content_visible': animationCurrentStep > 1,
          })}
        >
          {heading && (
            <PageHeading text={heading} className="front-page-hero__heading" />
          )}
          <div className="front-page-hero__text-holder">
            {introHtml && (
              <PoorText
                text={introHtml}
                className="front-page-hero__intro poor-text_in-front-page-hero"
              />
            )}
            <Parallax
              style={{
                overflow: 'visible',
              }}
              renderLayer={percentage => {
                const maxPercentage = 0.65;
                const maxLineOffset = 999;
                let calculatedLineOffset = 0;

                if (percentage > maxPercentage) {
                  calculatedLineOffset = maxLineOffset;
                } else {
                  calculatedLineOffset = calculateInterpolatedValue(
                    percentage,
                    maxLineOffset,
                    maxPercentage
                  );
                }

                return (
                  <OriginMatters
                    text={lang.originMattersLabel}
                    linePosition={OriginMattersLinePositions.left}
                    className="front-page-hero__slogan"
                    calculatedLineOffset={maxLineOffset - calculatedLineOffset}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="front-page-hero__image-holder">
          {image && (
            <Parallax
              style={{
                overflow: 'visible',
              }}
              renderLayer={percentage => {
                const maxPercentage = 1.3;
                const maxImageColPadding = 60;
                let imageColPadding = 0;

                if (percentage > maxPercentage) {
                  imageColPadding = maxImageColPadding;
                } else {
                  imageColPadding = calculateInterpolatedValue(
                    percentage,
                    maxImageColPadding,
                    maxPercentage
                  );
                }

                return (
                  <div
                    style={{
                      paddingLeft: isMobile
                        ? 0
                        : maxImageColPadding - imageColPadding,
                    }}
                  >
                    <div
                      className={cn('front-page-hero__image-holder-overlay', {
                        'front-page-hero__image-holder-overlay_visible':
                          animationCurrentStep > 0,
                        'front-page-hero__image-holder-overlay_tight':
                          animationCurrentStep <= 2,
                      })}
                    >
                      <div className="front-page-hero__image-centered-overlay">
                        <Image
                          {...image}
                          className="front-page-hero__image image_size_front-page-hero"
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

FrontPageHero.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  lang: PropTypes.shape({
    originMattersLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default FrontPageHero;
