import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import Link from 'components/link';
import LinkButton, {
  sizes as linkButtonSizes,
  themes as LinkButtonThemes,
} from 'components/link-button';
import PoorText from 'components/poor-text';
import OriginMatters, {
  linePositions as OriginMattersLinePositions,
} from 'components/origin-matters';
import imageResizer from 'js/image-resizer';
import useBreakpoints from 'hooks/use-breakpoints';

const FooterBanner = ({ heading, introHtml, backgroundImage, lang, link }) => {
  const { isMobile } = useBreakpoints(true);
  const [imageWidth, setImageWidth] = useState(0);

  useEffect(() => {
    setImageWidth(
      isMobile ? imageResizer.calcImageWidth(window.innerWidth, 100) : 1320
    );
  }, [isMobile]);

  return (
    <div className="footer-banner">
      {backgroundImage && (
        <div className="footer-banner__image-holder">
          <Image
            {...backgroundImage}
            fluid={true}
            width={imageWidth}
            height={isMobile ? null : 565}
            className="image_size_footer-banner"
          />
        </div>
      )}
      <div className="footer-banner__color-container">
        <div className="footer-banner__container">
          <div className="footer-banner__content">
            <div className="footer-banner__column">
              {heading && <h2 className="footer-banner__heading">{heading}</h2>}
              {introHtml && (
                <PoorText
                  text={introHtml}
                  className="poor-text_in-footer-banner footer-banner__text"
                />
              )}
              {link && (
                <LinkButton
                  {...link}
                  theme={LinkButtonThemes.light}
                  size={linkButtonSizes.small}
                />
              )}
            </div>
            <div className="footer-banner__column">
              <OriginMatters
                text={lang.originMattersLabel}
                linePosition={OriginMattersLinePositions.right}
                className="footer-banner__slogan"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterBanner.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  backgroundImage: PropTypes.exact(Image.propTypes),
  lang: PropTypes.shape({
    originMattersLabel: PropTypes.string.isRequired,
  }).isRequired,
  link: PropTypes.exact(Link.propTypes),
};

export default FooterBanner;
