import React from 'react';
import Link from 'components/link';
import MenuLink from 'components/models/menu-link';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SecondaryMenu = ({ links, className }) => {
  return links && links.length > 0 ? (
    <ul className={cn('secondary-menu', className)}>
      {links.map((link, index) => (
        <li key={index} className="secondary-menu__item">
          <Link
            {...link}
            className={cn('secondary-menu__link', {
              'secondary-menu__link_active': link.isActive,
            })}
          />
        </li>
      ))}
    </ul>
  ) : null;
};

SecondaryMenu.viewModelMeta = {
  className: 'ignore',
};

SecondaryMenu.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)).isRequired,
};

export default SecondaryMenu;
