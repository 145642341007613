const prefixesDictionary = {};

const generateUniqueId = prefix => {
  if (!prefixesDictionary[prefix]) {
    prefixesDictionary[prefix] = 1;
  } else {
    prefixesDictionary[prefix] += 1;
  }
  return `${prefix}-${prefixesDictionary[prefix]}`;
};

export default {
  generateUniqueId,
};
