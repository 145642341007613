/* eslint-disable react/prop-types */
// TODO: remove view-models/no-unused-meta and eslint-disable react/prop-types rule when this issue https://github.com/asbjornh/view-models/issues/18 is resolved
import React from 'react';
import ButtonBase from 'components/models/button-base';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ariaAttributesModel from 'components/models/aria-attributes/aria-attributes';

export const themes = {
  dark: 'dark',
  light: 'light',
};

export const iconPositions = {
  right: 'right',
  left: 'left',
};

const ToggleButton = ({
  className,
  text,
  tabindex,
  isActive,
  theme,
  onClick,
  iconName,
  activeIconName,
  iconPosition = iconPositions.right,
  ariaAttributes = {},
}) => {
  const leftIconPosition = iconPosition === iconPositions.left;
  return (
    <button
      className={cn('toggle-button', {
        [className]: className,
        'toggle-button_active': isActive,
        'toggle-button_theme-light': theme === themes.light,
        'toggle-button_icon-position-left': leftIconPosition,
      })}
      tabIndex={tabindex}
      onClick={onClick}
      {...ariaAttributes}
    >
      {leftIconPosition && (
        <Icon
          name={isActive ? activeIconName : iconName}
          className="toggle-button__icon toggle-button__icon_prefix"
        />
      )}
      <span
        className={cn('toggle-button__text', {
          'toggle-button__text_theme-light': theme === themes.light,
        })}
      >
        {text}
      </span>
      {!leftIconPosition && iconName && (
        <Icon
          name={isActive ? activeIconName : iconName}
          className="toggle-button__icon toggle-button__icon_suffix"
        />
      )}
    </button>
  );
};

ToggleButton.viewModelMeta = 'ignore';

ToggleButton.propTypes = Object.assign({}, ButtonBase.propTypes, {
  className: PropTypes.string,
  iconName: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  iconPosition: PropTypes.oneOf(Object.values(iconPositions)),
  isActive: PropTypes.bool,
  ariaAttributes: PropTypes.exact(ariaAttributesModel),
});

export default ToggleButton;
