import PropTypes from 'prop-types';

const SearchFormLang = {};

SearchFormLang.propTypes = {
  searchLabel: PropTypes.string.isRequired,
  searchInputAriaLabel: PropTypes.string.isRequired,
  searchFormAriaLabel: PropTypes.string.isRequired,
  searchBtnAriaLabel: PropTypes.string.isRequired,
  searchHint: PropTypes.string.isRequired,
};

export default SearchFormLang;
