import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import LinkButton, {
  sizes as linkButtonSizes,
  arrowDirections as linkButtonArrowDirections,
} from 'components/link-button';
import RecipeTeaser from 'components/recipe-teaser/recipe-teaser';
import useBreakpoints from 'hooks/use-breakpoints';
import Slider from 'react-slick';
import RecipeTeasersBlockSliderNavButton, {
  arrowDirections as sliderArrowDirections,
} from './recipe-teasers-block-slider-nav-button';
import useDidUpdateEffect from 'hooks/use-did-update-effect';
import cn from 'classnames';
import { Parallax } from 'react-parallax';

const RecipeTeasersBlock = ({
  className,
  heading,
  showAllLink,
  recipes,
  lang,
}) => {
  const { isDesktopLargerThanIpad } = useBreakpoints(true);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
  const [isMounted, setIsMounted] = React.useState(false);

  const slidesLength = recipes.length - 1;

  const sliderRef = useRef(null);
  const sliderStepsRef = useRef([]);

  const onSlideChange = currentSlideIndex => {
    setCurrentSlideIndex(currentSlideIndex);
  };

  const sliderSettings = {
    customPaging: function (i) {
      return (
        <button
          aria-label={i}
          className={cn('recipe-teasers-block__slider-paging-button', {
            'recipe-teasers-block__slider-paging-button_active':
              i === currentSlideIndex,
          })}
        ></button>
      );
    },
    dotsClass: 'recipe-teasers-block__slider-paging',
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <RecipeTeasersBlockSliderNavButton
        text={lang.nextButtonText}
        isDisabled={currentSlideIndex === slidesLength}
        arrowDirection={sliderArrowDirections.right}
      />
    ),
    prevArrow: (
      <RecipeTeasersBlockSliderNavButton
        text={lang.prevButtonText}
        isDisabled={currentSlideIndex === 0}
        arrowDirection={sliderArrowDirections.left}
      />
    ),
    afterChange: onSlideChange,
  };

  useEffect(() => {
    sliderStepsRef.current = sliderStepsRef.current.slice(0, recipes.length);
  }, [recipes]);

  useDidUpdateEffect(() => {
    if (sliderStepsRef?.current) {
      const currentSlideNode = sliderStepsRef?.current[currentSlideIndex];

      if (currentSlideNode) {
        currentSlideNode.focus();
      }
    }
  }, [currentSlideIndex, sliderStepsRef]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div
      className={cn('recipe-teasers-block', className)}
      aria-roledescription={
        isDesktopLargerThanIpad ? null : lang.carouselAriaRoleDescription
      }
    >
      {heading && <h2 className="recipe-teasers-block__heading">{heading}</h2>}
      {recipes && (
        <>
          {(!isMounted || isDesktopLargerThanIpad) && (
            <ul className="recipe-teasers-block__items">
              {recipes.map((recipe, index) => {
                let translateY = 0;

                switch (index) {
                  case 0:
                    translateY = -40;
                    break;
                  case 1:
                    translateY = 70;
                    break;
                  case 2:
                    translateY = -60;
                    break;
                  case 3:
                    translateY = 10;
                    break;
                }
                return (
                  <li className="recipe-teasers-block__item" key={index}>
                    <Parallax
                      style={{
                        overflow: 'visible',
                      }}
                      renderLayer={percentage => (
                        <div
                          style={{
                            transition:
                              percentage < 0.35
                                ? 'transform 150ms'
                                : 'transform 30ms',
                            transform: `translateY(${
                              percentage >= 0.3
                                ? percentage <= 1
                                  ? percentage * translateY
                                  : translateY
                                : 0
                            }px)`,
                          }}
                        >
                          <RecipeTeaser
                            {...recipe}
                            className="recipe-teasers-block__tesaer"
                            cookingTimeIconAriaLabel={
                              lang.cookingTimeIconAriaLabel
                            }
                            difficultyLevelIconAriaLabel={
                              lang.difficultyLevelIconAriaLabel
                            }
                            ratingIconAriaLabel={lang.ratingIconAriaLabel}
                          />
                        </div>
                      )}
                    ></Parallax>
                  </li>
                );
              })}
            </ul>
          )}

          {(!isMounted || !isDesktopLargerThanIpad) && (
            <Slider
              ref={sliderRef}
              {...sliderSettings}
              className="recipe-teasers-block__slider"
            >
              {recipes.map((recipe, index) => {
                return (
                  <RecipeTeaser
                    key={index}
                    {...recipe}
                    className="recipe-teasers-block__tesaer"
                    tabindex={index === currentSlideIndex ? null : -1}
                    ariaRoledescription={lang.teaserAriaRoleDescription}
                    ref={el => (sliderStepsRef.current[index] = el)}
                    cookingTimeIconAriaLabel={lang.cookingTimeIconAriaLabel}
                    difficultyLevelIconAriaLabel={
                      lang.difficultyLevelIconAriaLabel
                    }
                    ratingIconAriaLabel={lang.ratingIconAriaLabel}
                  />
                );
              })}
            </Slider>
          )}
        </>
      )}
      {showAllLink && (
        <LinkButton
          className="recipe-teasers-block__all-species-link"
          size={linkButtonSizes.small}
          arrowDirection={linkButtonArrowDirections.right}
          {...showAllLink}
        />
      )}
    </div>
  );
};

RecipeTeasersBlock.propTypes = {
  className: PropTypes.string,
  lang: PropTypes.shape({
    nextButtonText: PropTypes.string.isRequired,
    prevButtonText: PropTypes.string.isRequired,
    carouselAriaLabel: PropTypes.string.isRequired,
    carouselAriaRoleDescription: PropTypes.string.isRequired,
    teaserAriaRoleDescription: PropTypes.string.isRequired,
    cookingTimeIconAriaLabel: PropTypes.string.isRequired,
    difficultyLevelIconAriaLabel: PropTypes.string.isRequired,
    ratingIconAriaLabel: PropTypes.string.isRequired,
  }).isRequired,
  heading: PropTypes.string,
  showAllLink: PropTypes.exact(Link.propTypes),
  recipes: PropTypes.arrayOf(PropTypes.exact(RecipeTeaser.propTypes)),
};

RecipeTeasersBlock.viewModelMeta = {
  className: 'ignore',
};

export default RecipeTeasersBlock;
