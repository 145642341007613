import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import cn from 'classnames';

const positions = {
  right: 'right',
  left: 'left',
  center: 'center',
};

const ImageBlock = ({ image, caption, position }) => {
  return image ? (
    <div
      className={cn('image-block', {
        [`image-block_position_${position}`]: position,
      })}
    >
      <figure className="image-block__container">
        <Image
          {...image}
          className={cn('image-block__image', {
            [`image_size_image-block-${position}`]: position,
          })}
        />
        {caption && (
          <figcaption className="image-block__caption">{caption}</figcaption>
        )}
      </figure>
    </div>
  ) : null;
};

ImageBlock.propTypes = {
  image: PropTypes.exact(Image.propTypes).isRequired,
  caption: PropTypes.string,
  position: PropTypes.oneOf(Object.values(positions)).isRequired,
};

export default ImageBlock;
