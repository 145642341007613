import PropTypes from 'prop-types';

const ButtonBase = {};

ButtonBase.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  tabindex: PropTypes.number,
  openInNewTab: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ButtonBase;
