import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ text }) => {
  return <div className="error-message">{text}</div>;
};

ErrorMessage.propTypes = {
  text: PropTypes.string,
};

ErrorMessage.viewModelMeta = 'ignore';

export default ErrorMessage;
