import React from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/logo';
import FooterBanner from 'components/footer-banner';
import Link from 'components/link';
import SocialLink from 'components/social-link';
import RichText from 'components/rich-text';

const Footer = ({
  banner,
  heading,
  logo,
  column1,
  column2,
  socialLinks,
  privacy,
}) => {
  return (
    <div className="footer">
      {banner && <FooterBanner {...banner} />}
      <div className="footer__background" role="presentation"></div>
      <div className="footer__container">
        {heading && <strong className="footer__heading">{heading}</strong>}
        <div className="footer__columns">
          {column1 && (
            <div className="footer__column">
              {column1.heading && (
                <strong className="footer__column-heading">
                  {column1.heading}
                </strong>
              )}
              {column1.textHtml && (
                <RichText
                  text={column1.textHtml}
                  className="rich-text_in-footer-column"
                />
              )}
            </div>
          )}
          {column2 && (
            <div className="footer__column">
              {column2.heading && (
                <strong className="footer__column-heading">
                  {column2.heading}
                </strong>
              )}
              {column2.textHtml && (
                <RichText
                  text={column2.textHtml}
                  className="rich-text_in-footer-column"
                />
              )}
            </div>
          )}
          {socialLinks && (
            <div className="footer__column">
              {socialLinks.heading && (
                <strong className="footer__column-heading">
                  {socialLinks.heading}
                </strong>
              )}
              <ul className="footer__socials-list">
                {socialLinks.links &&
                  socialLinks.links.map((item, i) => (
                    <li key={i} className="footer__socials-item">
                      <SocialLink
                        {...item}
                        ariaAttributes={{
                          'aria-label': item.text,
                        }}
                      />
                    </li>
                  ))}
              </ul>
            </div>
          )}
          {privacy && (
            <div className="footer__column">
              {privacy.heading && (
                <strong className="footer__column-heading">
                  {privacy.heading}
                </strong>
              )}
              <ul className="footer__privacy">
                {privacy.links &&
                  privacy.links.map((item, i) => (
                    <li key={i} className="footer__privacy-item">
                      <Link {...item} className="footer__privacy-link" />
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
        {logo && logo.url && (
          <div className="footer__logo-holder">
            <Logo
              {...logo}
              className="footer__logo"
              imageClass="image_size_footer-logo"
            />
          </div>
        )}
      </div>
    </div>
  );
};

Footer.propTypes = {
  heading: PropTypes.string,
  banner: PropTypes.exact(FooterBanner.propTypes),
  logo: PropTypes.exact(Logo.propTypes),
  column1: PropTypes.shape({
    heading: PropTypes.string,
    textHtml: PropTypes.string,
  }),
  column2: PropTypes.shape({
    heading: PropTypes.string,
    textHtml: PropTypes.string,
  }),
  socialLinks: PropTypes.shape({
    heading: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape(SocialLink.propTypes)),
  }),
  privacy: PropTypes.shape({
    heading: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape(Link.propTypes)),
  }),
};

export default Footer;
