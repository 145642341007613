import React from 'react';
import Image from 'components/image';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useBreakpoints from 'hooks/use-breakpoints';

const SearchTeaser = ({
  url,
  heading,
  image,
  backgroundColor,
  categoryLabel,
  className,
}) => {
  const { isMobile } = useBreakpoints(true);

  return (
    <a
      href={url}
      className={cn('search-teaser', {
        [className]: className,
      })}
    >
      <div
        className="search-teaser__background"
        role="presentation"
        style={{ backgroundColor: backgroundColor }}
      ></div>
      <div className="search-teaser__container">
        <div className="search-teaser__image-aligner">
          <div
            className={cn('search-teaser__image-holder', {
              'search-teaser__image-holder_no-image': !image,
            })}
          >
            {image && (
              <Image
                {...image}
                fluid={isMobile}
                className="search-teaser__image image_size_search-teaser"
              />
            )}
          </div>
        </div>
        <div className="search-teaser__content">
          {categoryLabel && (
            <span className="search-teaser__category">{categoryLabel}</span>
          )}
          {heading && (
            <h2 className="search-teaser__heading">
              <span className="search-teaser__heading-text">{heading}</span>
            </h2>
          )}
        </div>
      </div>
    </a>
  );
};

SearchTeaser.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  heading: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  backgroundColor: PropTypes.string,
  categoryLabel: PropTypes.string,
};

SearchTeaser.viewModelMeta = {
  className: 'ignore',
};

export default SearchTeaser;
