import React from 'react';
import PropTypes from 'prop-types';
import ArticleTeaser from 'components/article-teaser';
import PoorText from 'components/poor-text';
import cn from 'classnames';
import ArticleTeaserSizes from 'components/article-teaser/article-teaser-sizes';

const ArticleTeasersBlock = ({ heading, introHtml, articles }) => {
  const calcItemSize = articleIndex => {
    const numberOfArticles = articles.length;

    if (numberOfArticles === 6 || numberOfArticles === 3) {
      return ArticleTeaserSizes.small;
    }
    if (numberOfArticles === 5) {
      if (articleIndex < 2) {
        return ArticleTeaserSizes.medium;
      }
      return ArticleTeaserSizes.small;
    }
    if (numberOfArticles === 2 || numberOfArticles === 4) {
      return ArticleTeaserSizes.medium;
    }
    if (numberOfArticles === 1) {
      return ArticleTeaserSizes.large;
    }
    //Number of articles restricted to max 6 items for now, return default size just in case we have more
    return ArticleTeaserSizes.small;
  };

  return (
    <div className="article-teasers-block">
      {heading && <h2 className="article-teasers-block__heading">{heading}</h2>}
      {introHtml && (
        <PoorText
          text={introHtml}
          className="article-teasers-block__intro poor-text_in-article-teasers-block"
        />
      )}
      {articles && (
        <ul className="article-teasers-block__items">
          {articles.map((article, index) => {
            const itemSIze = calcItemSize(index);

            return (
              <li
                key={index}
                className={cn('article-teasers-block__item', {
                  'article-teasers-block__item_width_half':
                    itemSIze === ArticleTeaserSizes.medium,
                  'article-teasers-block__item_width_full':
                    itemSIze === ArticleTeaserSizes.large,
                })}
              >
                <ArticleTeaser
                  {...article}
                  size={itemSIze}
                  headingTagLevel={heading ? 3 : 2}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

ArticleTeasersBlock.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.exact(ArticleTeaser.propTypes)),
};

export default ArticleTeasersBlock;
