import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const RichText = ({ text, className }) => {
  return !text ? null : (
    <div
      className={cn('rich-text', className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

RichText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

RichText.viewModelMeta = 'ignore';

export default RichText;
