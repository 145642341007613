import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import { Parallax } from 'react-parallax';
import ParallaxBgImage from 'components/parallax-bg-image/parallax-bg-image';
import ParallaxText from 'components/parallax-text/parallax-text';

const TextWithBackgroundBlock = ({
  text,
  textBackgroundColor,
  textColor,
  backgroundImage,
}) => {
  return (
    <div className="block_no-gap text-with-background-block">
      <ParallaxBgImage
        imageClassName="image_size_text-with-background-block"
        backgroundImage={backgroundImage}
      />
      <div className="text-with-background-block__container">
        <div className="text-with-background-block__inner">
          <Parallax
            className="text-with-background-block__text-animation-container"
            renderLayer={percentage => (
              <>
                {text && (
                  <ParallaxText
                    text={text}
                    percentage={percentage}
                    textBackgroundColor={textBackgroundColor}
                    textColor={textColor}
                  />
                )}
              </>
            )}
          ></Parallax>
        </div>
      </div>
    </div>
  );
};
TextWithBackgroundBlock.propTypes = {
  text: PropTypes.string,
  textBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundImage: PropTypes.exact(Image.propTypes),
};

export default TextWithBackgroundBlock;
