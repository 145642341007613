function pluralOrSingularText(amount, plural, singular) {
  let text = null;

  if (amount || amount === 0) {
    if (amount <= 1 || amount === 0) {
      singular ? (text = singular) : null;
    } else {
      plural ? (text = plural) : null;
    }
  }

  return text;
}

export default pluralOrSingularText;
