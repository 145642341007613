import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import useToggle from '../../hooks/use-toggle';
import Image from 'components/image';
import Icon from 'components/icon';
import LoadingIndicator, {
  themes as LoadingIndicatorThemes,
} from 'components/loading-indicator/loading-indicator';
import ReactPlayer from 'react-player';
import cn from 'classnames';

const EmbeddedVideoBlock = ({ caption, previewImage, videoUrl, lang }) => {
  const [playing, setPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isActive, activate, deactivate } = useToggle(false);

  const onPlayBtnClick = () => {
    setIsLoading(true);
    activate();
  };

  const onPlayerStart = () => {
    setPlaying(true);
    setIsLoading(false);
  };

  const onPlayerError = () => {
    setPlaying(false);
    setIsLoading(false);
    deactivate();
  };

  return (
    <div className="embedded-video-block">
      <div className="embedded-video-block__container">
        <figure className="embedded-video-block__figure">
          <div className="embedded-video-block__video-holder">
            {previewImage && (
              <Image
                className={cn(
                  'embedded-video-block__image image_size_embedded-video-block',
                  {
                    'embedded-video-block__image_hidden': playing,
                  }
                )}
                {...previewImage}
              />
            )}
            {!playing && (
              <div className="embedded-video-block__play-btn-holder">
                <button
                  className="embedded-video-block__play-btn"
                  onClick={onPlayBtnClick}
                  aria-label={lang.playVideoButtonAriaLabel}
                >
                  {isLoading ? (
                    <LoadingIndicator
                      theme={LoadingIndicatorThemes.light}
                      className="embedded-video-block__loading"
                    />
                  ) : (
                    <Fragment>
                      <Icon
                        name="play"
                        className="embedded-video-block__play-btn-icon"
                      />
                      <Icon
                        name="play-filled"
                        className="embedded-video-block__play-btn-icon embedded-video-block__play-btn-icon_filled"
                      />
                    </Fragment>
                  )}
                </button>
                <div className="embedded-video-block__play-btn-outline"></div>
              </div>
            )}
            {isActive && (
              <ReactPlayer
                playing={true}
                url={videoUrl}
                controls={true}
                onStart={onPlayerStart}
                onError={onPlayerError}
              />
            )}
          </div>
          {caption && (
            <figcaption className="embedded-video-block__caption">
              {caption}
            </figcaption>
          )}
        </figure>
      </div>
    </div>
  );
};

EmbeddedVideoBlock.propTypes = {
  caption: PropTypes.string,
  previewImage: PropTypes.exact(Image.propTypes),
  videoUrl: PropTypes.string.isRequired,
  lang: PropTypes.shape({
    playVideoButtonAriaLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default EmbeddedVideoBlock;
