/* eslint-disable react/prop-types */
// TODO: remove view-models/no-unused-meta and eslint-disable react/prop-types rule when this issue https://github.com/asbjornh/view-models/issues/18 is resolved
import React, { forwardRef } from 'react';
import ButtonBase from 'components/models/button-base';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ariaAttributesModel from 'components/models/aria-attributes/aria-attributes';

export const themes = {
  dark: 'dark',
  light: 'light',
};

export const sizes = {
  normal: 'normal',
  small: 'small',
};

export const arrowDirections = {
  right: 'right',
  left: 'left',
};

const LinkButton = forwardRef(
  (
    {
      className,
      openInNewTab = false,
      url,
      text,
      tabindex,
      isActive,
      theme,
      onClick,
      size = sizes.normal,
      arrowDirection = arrowDirections.right,
      reverseIconDirectionOnHover,
      ariaAttributes = {},
    },
    ref
  ) => {
    const RootTag = url ? 'a' : 'button';
    const leftDirection = arrowDirection === arrowDirections.left;
    return (
      <RootTag
        ref={ref}
        href={url}
        className={cn('link-button', {
          [className]: className,
          'link-button_active': isActive,
          'link-button_theme-light': theme === themes.light,
          'link-button_arrow-position-left': leftDirection,
          'link-button_reversed-arrow-direction-on-hover':
            reverseIconDirectionOnHover,
          'link-button_small': size === sizes.small,
        })}
        target={openInNewTab ? '_blank' : null}
        rel={openInNewTab ? 'nofollow noopener' : null}
        tabIndex={tabindex}
        onClick={onClick}
        {...ariaAttributes}
      >
        {((reverseIconDirectionOnHover && !leftDirection) || leftDirection) && (
          <Icon
            name={leftDirection ? 'arrow-left' : 'arrow-right'}
            className="link-button__arrow link-button__arrow_prefix"
          />
        )}
        <span
          className={cn('link-button__text', {
            'link-button__text_theme-light': theme === themes.light,
          })}
        >
          {text}
        </span>
        {((reverseIconDirectionOnHover && leftDirection) || !leftDirection) && (
          <Icon
            name={leftDirection ? 'arrow-left' : 'arrow-right'}
            className="link-button__arrow link-button__arrow_suffix"
          />
        )}
      </RootTag>
    );
  }
);

LinkButton.viewModelMeta = 'ignore';

LinkButton.propTypes = Object.assign({}, ButtonBase.propTypes, {
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  size: PropTypes.oneOf(Object.values(sizes)),
  arrowDirection: PropTypes.oneOf(Object.values(arrowDirections)),
  isActive: PropTypes.bool,
  reverseIconDirectionOnHover: PropTypes.bool,
  ariaAttributes: PropTypes.exact(ariaAttributesModel),
});

export default LinkButton;
