import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import PoorText from 'components/poor-text';
import Link from 'components/link';
import PageHeading from 'components/page-heading';
import LinkButton, {
  sizes as linkButtonSizes,
  arrowDirections as linkButtonArrowDirections,
} from 'components/link-button/link-button';
import RecipeDetails from 'components/recipe-details';
import recipeMessageBus from 'js/recipe-message-bus';
import ParallaxHeroBackground from 'components/parallax-hero-background';
import useAnimationStepSwitcher, {
  ANIMATION_STEPS,
} from 'hooks/use-animation-step-switcher';
import cn from 'classnames';
import { calculateInterpolatedValue } from 'js/calc-utils';
import { Parallax } from 'react-parallax';
import useBreakpoints from 'hooks/use-breakpoints';

const RecipePageHero = ({
  heading,
  introHtml,
  allRecipesLink,
  image,
  rating,
  cookingTime,
  difficultyLevel,
  lang = {
    cookingTimeIconAriaLabel: 'Cooking time',
    difficultyLevelIconAriaLabel: 'Difficulty level',
    ratingIconAriaLabel: 'Rating',
  },
}) => {
  const { isMobile } = useBreakpoints(true);
  const animationCurrentStep = useAnimationStepSwitcher(
    ANIMATION_STEPS.twoLayersHero
  );

  const [currentRating, setCurrentRating] = useState(rating);
  useEffect(() => {
    const token = recipeMessageBus.subscribeToNewRating((msg, data) => {
      setCurrentRating(data);
    });

    return () => {
      recipeMessageBus.unsubscribeFromNewRating(token);
    };
  }, []);

  return (
    <div className="recipe-page-hero">
      <ParallaxHeroBackground
        backgroundColor="#FFAE9C"
        useParallaxOnMobile={false}
        className={cn('recipe-page-hero__background', {
          'recipe-page-hero__background_visible': animationCurrentStep > 0,
        })}
      />
      <div
        className={cn('recipe-page-hero__container', {
          'recipe-page-hero__container_tall': animationCurrentStep > 2,
        })}
      >
        <div className="recipe-page-hero__image-holder">
          <Parallax
            style={{
              overflow: 'visible',
            }}
            renderLayer={percentage => {
              const maxPercentage = 1.3;
              const maxImageColPadding = 60;
              let imageColPadding = 0;

              if (percentage > maxPercentage) {
                imageColPadding = maxImageColPadding;
              } else {
                imageColPadding = calculateInterpolatedValue(
                  percentage,
                  maxImageColPadding,
                  maxPercentage
                );
              }

              return (
                <div
                  style={{
                    paddingRight: isMobile
                      ? 0
                      : maxImageColPadding - imageColPadding,
                  }}
                >
                  <div
                    className={cn('recipe-page-hero__image-holder-overlay', {
                      'recipe-page-hero__image-holder-overlay_visible':
                        animationCurrentStep > 0,
                      'recipe-page-hero__image-holder-overlay_tight':
                        animationCurrentStep <= 2,
                    })}
                  >
                    <div className="recipe-page-hero__image-centered-overlay">
                      <Image
                        {...image}
                        className="recipe-page-hero__image image_size_recipe-page-hero"
                      />
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
        <div
          className={cn('recipe-page-hero__content', {
            'recipe-page-hero__content_visible': animationCurrentStep > 1,
          })}
        >
          {allRecipesLink && (
            <LinkButton
              className="recipe-page-hero__all-recipes-link"
              size={linkButtonSizes.small}
              arrowDirection={linkButtonArrowDirections.left}
              {...allRecipesLink}
            />
          )}
          {heading && <PageHeading size="small" text={heading} />}
          {introHtml && (
            <PoorText
              text={introHtml}
              className="recipe-page-hero__intro poor-text_in-recipe-page-hero"
            />
          )}
          <RecipeDetails
            className="recipe-page-hero__details"
            cookingTime={cookingTime}
            difficultyLevel={difficultyLevel}
            rating={currentRating}
            cookingTimeIconAriaLabel={lang.cookingTimeIconAriaLabel}
            difficultyLevelIconAriaLabel={lang.difficultyLevelIconAriaLabel}
            ratingIconAriaLabel={lang.ratingIconAriaLabel}
            useFocusableDetails={true}
          />
        </div>
      </div>
    </div>
  );
};

RecipePageHero.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  allRecipesLink: PropTypes.exact(Link.propTypes),
  rating: PropTypes.string,
  cookingTime: PropTypes.string,
  difficultyLevel: PropTypes.string,
  lang: PropTypes.shape({
    cookingTimeIconAriaLabel: PropTypes.string.isRequired,
    difficultyLevelIconAriaLabel: PropTypes.string.isRequired,
    ratingIconAriaLabel: PropTypes.string.isRequired,
  }).isRequired,
};

export default RecipePageHero;
