import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Ingredient from './ingredient';
import fullCopy from 'js/fullCopy.js';
import IconButton from 'components/icon-button';

const Ingredients = ({
  lang = {
    increaseNumberOfServingsAriaLabel: 'Høyere antall porsjoner',
    decreaseNumberOfServingsAriaLabel: 'Lavere antall porsjoner',
  },
  sections,
  servingsDefaultNumber,
}) => {
  const servingNumber = servingsDefaultNumber || 1;
  const [currentSections, setCurrentSections] = useState([]);

  const [currentServings, setCurrentServings] = useState(servingNumber);

  const handleKeyDown = e => {
    if (e.key === '-' || e.key === '.') {
      e.preventDefault();
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedText = (e.clipboardData || window.clipboardData).getData(
      'text'
    );
    const sanitizedText = pastedText.replace(/[-.]/g, '');
    const updatedText =
      e.target.value.slice(0, e.target.selectionStart) +
      sanitizedText +
      e.target.value.slice(e.target.selectionEnd);
    setCurrentServings(parseInt(updatedText));
  };

  const handleServingsInputChange = v => {
    setCurrentServings(parseInt(v));
  };

  const increaseServings = () => {
    setCurrentServings(currentServings + 1);
  };

  const decreaseServings = () => {
    setCurrentServings(
      currentServings > 1 ? currentServings - 1 : currentServings
    );
  };

  const calcAmount = (amount, ratio) => {
    const str = (amount * ratio).toFixed(2);
    const endingZeroes = /(\.0*$)/;
    const tmpSrt = str.replace(endingZeroes, '');
    return parseFloat(tmpSrt);
  };

  const updateSections = () => {
    const newSections = fullCopy(sections);
    const servings = currentServings > 1 ? currentServings : 1;
    const ratio = servings / servingNumber;

    newSections.forEach(section => {
      section.ingredients.forEach(ingredient => {
        ingredient.amount = calcAmount(ingredient.amount, ratio);
      });
    });

    setCurrentSections(newSections);
  };

  React.useEffect(() => {
    updateSections();
  }, []);

  React.useEffect(() => {
    updateSections();
  }, [currentServings]);

  return (
    <section className="ingredients">
      <div className="ingredients__servings">
        <div className="ingredients__servings-panel">
          <label
            className="ingredients__servings-label"
            htmlFor="servingsCount"
          >
            {lang.servingsLabel}
          </label>
          <input
            className="ingredients__servings-count"
            id="servingsCount"
            type="number"
            min="1"
            value={isNaN(currentServings) ? '' : currentServings}
            onChange={e => handleServingsInputChange(e.target.value)}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
          />
          <div className="ingredients__servings-buttons">
            <IconButton
              iconName="arrow-up"
              className="ingredients__servings-button ingredients__servings-button_plus"
              tabIndex="0"
              onClick={increaseServings}
              ariaAttributes={{
                'aria-label': lang.increaseNumberOfServingsAriaLabel,
              }}
            />
            <IconButton
              iconName="arrow-down"
              className="ingredients__servings-button ingredients__servings-button_minus"
              tabIndex="0"
              onClick={decreaseServings}
              ariaAttributes={{
                'aria-label': lang.decreaseNumberOfServingsAriaLabel,
              }}
            />
          </div>
        </div>
      </div>
      <h2 className="ingredients__heading">{lang.ingredientsHeading}</h2>
      {currentSections && (
        <div aria-live="polite" aria-atomic="true">
          {currentSections.map((section, sectionIndex) => (
            <div className="ingredients__section" key={section + sectionIndex}>
              {section.heading && (
                <h3 className="ingredients__section-heading">
                  {section.heading}
                </h3>
              )}
              {section.ingredients &&
                section.ingredients.map((ingredient, ingredientIndex) => {
                  const showIngredient =
                    ingredient.amount !== null &&
                    ingredient.amount !== undefined;
                  return showIngredient ? (
                    <div
                      itemProp="ingredients"
                      key={'ingredient' + ingredientIndex}
                      className="ingredients__section-item"
                    >
                      <Ingredient {...ingredient} />
                    </div>
                  ) : null;
                })}
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

Ingredients.propTypes = {
  lang: PropTypes.shape({
    ingredientsHeading: PropTypes.string.isRequired,
    servingsLabel: PropTypes.string.isRequired,
    increaseNumberOfServingsAriaLabel: PropTypes.string.isRequired,
    decreaseNumberOfServingsAriaLabel: PropTypes.string.isRequired,
  }).isRequired,
  servingsDefaultNumber: PropTypes.number,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          printOrder: PropTypes.oneOf(['aui', 'iau', 'iua']).isRequired, //Should be the same as print-orders.js object
          amount: PropTypes.number.isRequired,
          unit: PropTypes.shape({
            plural: PropTypes.string,
            singular: PropTypes.string,
          }),
          name: PropTypes.shape({
            plural: PropTypes.string,
            singular: PropTypes.string,
          }),
        })
      ),
    })
  ),
};

Ingredients.viewModelMeta = {
  sections: [{ ingredients: [{ amount: 'double' }] }],
};

export default Ingredients;
