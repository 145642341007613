export const calculateInterpolatedValue = (
  abstractUnit,
  maxValue,
  maxAbstractUnit,
  minValue
) => {
  const safeMinValue = minValue ?? 0;
  const minAbstractUnit = 1;

  const interpolatedValue =
    safeMinValue +
    ((abstractUnit - minAbstractUnit) * (maxValue - safeMinValue)) /
      (maxAbstractUnit - minAbstractUnit);
  return interpolatedValue;
};
