import React from 'react';
import PropTypes from 'prop-types';
import RecipeFilter from 'components/recipe-filter';
import FilterHeading from 'components/filter-heading';

import cn from 'classnames';

const RecipeFilters = ({
  heading,
  filters,
  className,
  id,
  onFilterChange = () => {},
}) => {
  return filters && filters.length > 0 ? (
    <div
      className={cn('recipe-filters', {
        [className]: className,
      })}
      id={id}
    >
      {heading && (
        <FilterHeading
          text={heading}
          tagName="strong"
          className="recipe-filters__heading"
        />
      )}
      <div className="recipe-filters__list">
        {filters.map((filter, index) => {
          return (
            <div className="recipe-filters__item" key={index}>
              <RecipeFilter {...filter} onChange={onFilterChange} />
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

RecipeFilters.propTypes = {
  heading: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.exact(RecipeFilter.propTypes)),
  onFilterChange: PropTypes.func,
};

RecipeFilters.viewModelMeta = 'ignore';

export default RecipeFilters;
