import React from 'react';
import PropTypes from 'prop-types';
import PageHeading from 'components/page-heading';
import PoorText from 'components/poor-text';
import ParallaxHeroBackground from 'components/parallax-hero-background';
import useAnimationStepSwitcher, {
  ANIMATION_STEPS,
} from 'hooks/use-animation-step-switcher';
import cn from 'classnames';

const SimplePageHero = ({ heading, backgroundColor, introHtml, children }) => {
  const animationCurrentStep = useAnimationStepSwitcher(
    ANIMATION_STEPS.oneLayerHero
  );

  return (
    <div className="simple-page-hero">
      <ParallaxHeroBackground
        backgroundColor={backgroundColor}
        className={cn('simple-page-hero__background', {
          'simple-page-hero__background_visible': animationCurrentStep > 0,
        })}
      />
      <div
        className={cn('simple-page-hero__container', {
          'simple-page-hero__container_visible': animationCurrentStep > 1,
          'simple-page-hero__container_tall': animationCurrentStep > 2,
        })}
      >
        {heading && (
          <PageHeading text={heading} className="simple-page-hero__heading" />
        )}
        {introHtml && (
          <PoorText
            text={introHtml}
            className="simple-page-hero__intro poor-text_in-simple-page-hero"
          />
        )}
        {children && (
          <div className="simple-page-hero__content">{children}</div>
        )}
      </div>
    </div>
  );
};

SimplePageHero.propTypes = {
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

SimplePageHero.viewModelMeta = { children: 'ignore' };

export default SimplePageHero;
