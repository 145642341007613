import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import ParallaxBgImage from 'components/parallax-bg-image/parallax-bg-image';
import ParallaxText from 'components/parallax-text/parallax-text';
import { Parallax } from 'react-parallax';
import { ANIMATION_PERCENTAGE } from '../../constants/parallax';

const QuoteWithBackgroundBlock = ({
  text,
  author,
  authorTitle,
  quoteBackgroundColor,
  textColor,
  backgroundImage,
}) => {
  const textAnimationPercentage = ANIMATION_PERCENTAGE.text;
  return (
    <div className="block_no-gap quote-with-background-block">
      <ParallaxBgImage
        imageClassName="image_size_quote-with-background-block"
        backgroundImage={backgroundImage}
      />

      {text && (
        <div className="quote-with-background-block__container">
          <div className="quote-with-background-block__inner">
            <Parallax
              className="quote-with-background-block__text-animation-container"
              renderLayer={percentage => (
                <>
                  <figure className="quote-with-background-block__quote">
                    <blockquote
                      className="quote-with-background-block__text"
                      style={{ color: textColor }}
                    >
                      {text && (
                        <ParallaxText
                          text={text}
                          percentage={percentage}
                          textBackgroundColor={quoteBackgroundColor}
                          textColor={textColor}
                          textAnimationPercentage={textAnimationPercentage}
                        />
                      )}
                    </blockquote>

                    {(author || authorTitle) && (
                      <figcaption
                        className="quote-with-background-block__caption"
                        style={{
                          color: textColor,
                          transition:
                            percentage <= textAnimationPercentage
                              ? 'left 500ms ease-out 350ms, background-position 350ms ease-in 700ms'
                              : 'left 500ms ease-in 0ms, background-position 350ms ease-in 700ms',
                          position: 'relative',
                          bottom: 0,
                          left:
                            percentage > textAnimationPercentage
                              ? '0%'
                              : '-100%',

                          backgroundSize: '100% 200%',
                          backgroundPosition:
                            percentage > textAnimationPercentage && '100% 100%',
                          backgroundImage: `linear-gradient(${textColor}, ${textColor} 50%, ${quoteBackgroundColor} 50%, ${quoteBackgroundColor})`,
                        }}
                      >
                        {author && (
                          <div className="quote-with-background-block__author">
                            {author}
                          </div>
                        )}
                        {authorTitle && (
                          <div className="quote-with-background-block__title">
                            {authorTitle}
                          </div>
                        )}
                      </figcaption>
                    )}
                  </figure>
                </>
              )}
            ></Parallax>
          </div>
        </div>
      )}
    </div>
  );
};
QuoteWithBackgroundBlock.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
  authorTitle: PropTypes.string,
  quoteBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  backgroundImage: PropTypes.exact(Image.propTypes),
};

export default QuoteWithBackgroundBlock;
