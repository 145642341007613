import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import SimplePageHero from 'components/simple-page-hero/simple-page-hero';
import RecipeTeaser from 'components/recipe-teaser/recipe-teaser';
import RecipeFilters from 'components/recipe-filters/recipe-filters';
import ToggleButton from 'components/toggle-button/toggle-button';
import ErrorMessage from 'components/error-message/error-message';
import NoResults from 'components/no-results/no-results';
import Pager from 'components/pager/pager';
import SearchForm, {
  sizes as searchFormSizes,
} from 'components/search-form/search-form';
import LoadingIndicator from 'components/loading-indicator/loading-indicator';
import apiHelper from 'js/api-helper';
import formHelper from 'js/form-helper';
import idHelper from 'js/id-helper';
import stringHelper from 'js/string-helper';
import useDidUpdateEffect from 'hooks/use-did-update-effect';
import useBreakpoints from 'hooks/use-breakpoints';
import recipeTeaserOrientations from 'components/recipe-teaser/recipe-teaser-orientations';
import PagerLang from 'components/models/pager-lang/pager-lang';
import SearchFormLang from 'components/models/search-form-lang/search-form-lang';

const searchQueryParamName = 'q';
const pageNumberQueryParamName = 'page';
const skipFiltersQueryParamName = 'skipFilters';

const RecipeList = ({ heading, loadItemsEndpoint, lang }) => {
  const [results, setResults] = useState(null);
  const [query, setQuery] = useState(formHelper.createQuery());
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filtersOpenedOnMobile, setFiltersOpenedOnMobile] = useState(false);

  const { isMobile } = useBreakpoints(true);

  const uniqueRecipeFiltersPanelId = useMemo(
    () => idHelper.generateUniqueId('recipe-filters-panel'),
    []
  );

  const initLoadedFilter = filter => {
    formHelper.updateFilterFromQuery(query, filter.name, filter.items);
  };

  const loadItems = () => {
    const requestQuery = { ...query };

    if (results?.filters && results?.pager && query.page !== 1) {
      requestQuery[skipFiltersQueryParamName] = true;
    }

    setShowError(false);
    setIsLoading(true);
    apiHelper
      .execute(loadItemsEndpoint, requestQuery)
      .then(results => {
        if (results) {
          if (results.filters) {
            results.filters.forEach(initLoadedFilter);
          }

          const { filters: responseFilters, ...restResponseResults } = results;

          setResults(prevResults => ({
            ...prevResults,
            ...restResponseResults,
            ...(responseFilters && {
              filters: responseFilters,
            }),
          }));
        }
      })
      .catch(() => {
        setShowError(true);
        setResults(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // eslint-disable-next-line no-unused-vars
  const updateQuery = (updateFunc = q => {}) => {
    setQuery(q => {
      q = Object.assign({}, q);
      updateFunc(q);
      return q;
    });
  };

  const init = () => {
    updateQuery(q => formHelper.fillQueryFromPageQueryString(q));
  };

  useDidUpdateEffect(() => {
    formHelper.updatePageQueryString(query);
    loadItems();
  }, [query]);

  useDidUpdateEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [results?.pager?.page]);

  useEffect(() => {
    init();
  }, []);

  const onFilterChange = e => {
    updateQuery(q => {
      q[pageNumberQueryParamName] = 1;

      return formHelper.updateQueryFromFilter(q, e.name, e.items);
    });
  };

  const onSearch = val => {
    updateQuery(q => {
      q[pageNumberQueryParamName] = 1;

      return formHelper.setToQueryTextInputValue(q, {
        name: searchQueryParamName,
        value: val,
      });
    });
  };

  const onPageChange = page => {
    updateQuery(q => (q[pageNumberQueryParamName] = page));
  };

  return (
    <div className="recipe-list">
      <SimplePageHero heading={heading} backgroundColor="#EEE4DD">
        <div className="recipe-list__settings-panel">
          <div className="recipe-list__search-form-holder">
            <SearchForm
              lang={lang.searchForm}
              query={query[searchQueryParamName]}
              size={searchFormSizes.large}
              className="recipe-list__search-form search-form_in-recipe-list"
              onSearch={onSearch}
            />
            {results && !isLoading && (
              <p
                className="recipe-list__hits-text"
                role="region"
                aria-live="polite"
              >
                {query.q
                  ? stringHelper.format(
                      lang.searchHitsText,
                      query.q,
                      results.totalCount
                    )
                  : lang.emptySearchTermText}
              </p>
            )}
          </div>
          {results?.filters && (
            <div className="recipe-list__filters">
              <ToggleButton
                text={
                  filtersOpenedOnMobile
                    ? lang.hideFilterOptionsLabel
                    : lang.showFilterOptionsLabel
                }
                className="recipe-list__filters-toggle-btn"
                onClick={() => setFiltersOpenedOnMobile(!filtersOpenedOnMobile)}
                iconName={filtersOpenedOnMobile ? 'minus-sign' : 'plus-sign'}
                iconPosition="left"
                ariaAttributes={{
                  'aria-expanded': filtersOpenedOnMobile,
                  'aria-controls': uniqueRecipeFiltersPanelId,
                }}
              />
              <RecipeFilters
                id={uniqueRecipeFiltersPanelId}
                heading={lang.filterLabel}
                filters={results.filters}
                onFilterChange={onFilterChange}
                className={`recipe-list__filters-panel ${
                  filtersOpenedOnMobile
                    ? 'recipe-list__filters-panel_visible'
                    : ''
                }`}
              />
            </div>
          )}
        </div>
      </SimplePageHero>
      <div className="recipe-list__results-container animated-content animated-content_delayed-appearance">
        {results?.items && results.items.length > 0 ? (
          <ul className="recipe-list__teasers">
            {results.items.map(recipe => (
              <li key={recipe.id} className="recipe-list__teaser-item">
                <RecipeTeaser
                  {...recipe}
                  orientation={
                    isMobile
                      ? recipeTeaserOrientations.horizontal
                      : recipeTeaserOrientations.vertical
                  }
                  className="recipe-list__teaser"
                  headingTagLevel={2}
                  cookingTimeIconAriaLabel={lang.cookingTimeIconAriaLabel}
                  difficultyLevelIconAriaLabel={
                    lang.difficultyLevelIconAriaLabel
                  }
                  ratingIconAriaLabel={lang.ratingIconAriaLabel}
                />
              </li>
            ))}
          </ul>
        ) : null}
        {results?.items && results.items.length === 0 && (
          <NoResults
            heading={lang.noListResultsHeading}
            text={lang.noListResultsText}
          />
        )}
        {showError && <ErrorMessage text={lang.errorMessage} />}
        {results?.pager && (
          <Pager
            {...results?.pager}
            page={+query?.page || 1}
            className="recipe-list__pager"
            lang={lang.pager}
            onPageChange={onPageChange}
          />
        )}
        {isLoading && <LoadingIndicator />}
      </div>
    </div>
  );
};

RecipeList.propTypes = {
  heading: PropTypes.string,
  loadItemsEndpoint: PropTypes.string.isRequired,
  lang: PropTypes.shape({
    errorMessage: PropTypes.string.isRequired,
    filterLabel: PropTypes.string.isRequired,
    showFilterOptionsLabel: PropTypes.string.isRequired,
    hideFilterOptionsLabel: PropTypes.string.isRequired,
    noListResultsHeading: PropTypes.string.isRequired,
    noListResultsText: PropTypes.string.isRequired,
    searchHitsText: PropTypes.string.isRequired,
    emptySearchTermText: PropTypes.string.isRequired,
    pager: PropTypes.shape(PagerLang.propTypes).isRequired,
    searchForm: PropTypes.shape(SearchFormLang.propTypes).isRequired,
    cookingTimeIconAriaLabel: PropTypes.string.isRequired,
    difficultyLevelIconAriaLabel: PropTypes.string.isRequired,
    ratingIconAriaLabel: PropTypes.string.isRequired,
  }),
};

export default RecipeList;
