/* eslint-disable react/no-multi-comp */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SearchFormLang from 'components/models/search-form-lang/search-form-lang';
import SearchForm, {
  sizes as searchFormSizes,
  themes as searchFormThemes,
} from 'components/search-form/search-form';
import Icon from 'components/icon';
import cn from 'classnames';

const quickSearchFormId = 'quick-search-form';

const QuickSearch = ({
  className,
  searchPageUrl,
  lang,
  isSearchExpanded,
  onSearchExpand,
}) => {
  const searchFormRef = useRef(null);

  useEffect(() => {
    if (searchFormRef && searchFormRef.current) {
      !isSearchExpanded && searchFormRef.current.clearInput();
    }
  }, [isSearchExpanded, searchFormRef]);

  const expandSearch = () => {
    onSearchExpand();

    setTimeout(() => {
      if (searchFormRef && searchFormRef.current) {
        searchFormRef.current.focusInput();
      }
    }, 200);
  };

  return (
    <div className={cn('quick-search', className)}>
      <button
        className={cn('quick-search__btn', {
          'quick-search__btn_hidden': isSearchExpanded,
        })}
        onClick={expandSearch}
        aria-expanded={isSearchExpanded}
        aria-controls={quickSearchFormId}
      >
        <span className="quick-search__btn-text">{lang.searchLabel}</span>
        <Icon
          className="quick-search__icon"
          name="search"
          useFill
          style={{ color: '#fff' }}
        />
      </button>
      <div
        className={cn('quick-search__holder', {
          'quick-search__holder_expanded': isSearchExpanded,
        })}
        aria-hidden={!isSearchExpanded}
        id={quickSearchFormId}
      >
        <SearchForm
          ref={searchFormRef}
          displayTopLabel={false}
          lang={lang}
          searchPageUrl={searchPageUrl}
          size={searchFormSizes.small}
          theme={searchFormThemes.light}
          className="quick-search__search-form"
        />
      </div>
    </div>
  );
};

QuickSearch.viewModelMeta = {
  className: 'ignore',
  onSearchExpand: 'ignore',
  isSearchExpanded: 'ignore',
};

QuickSearch.propTypes = {
  className: PropTypes.string,
  searchPageUrl: PropTypes.string,
  isSearchExpanded: PropTypes.bool,
  lang: PropTypes.shape(SearchFormLang.propTypes).isRequired,
  onSearchExpand: PropTypes.func,
};

export default QuickSearch;
