import React from 'react';
import Icon from 'components/icon';
import PropTypes from 'prop-types';
import cn from 'classnames';

export const sizes = {
  normal: 'normal',
  small: 'small',
};

const IconLabel = ({
  className,
  icon,
  size = sizes.normal,
  text,
  iconAriaLabel,
  tabIndex,
}) => {
  return (
    <div
      className={cn('icon-label', className, {
        'icon-label_small': size === sizes.small,
      })}
      tabIndex={tabIndex}
    >
      <Icon name={icon} className="icon-label__icon" />
      {iconAriaLabel && (
        <span className="icon-label__text icon-label__text_hidden">
          {iconAriaLabel}
        </span>
      )}
      <span className="icon-label__text">{text}</span>
    </div>
  );
};

IconLabel.viewModelMeta = 'ignore';

IconLabel.propTypes = {
  className: PropTypes.string,
  iconAriaLabel: PropTypes.string,
  tabIndex: PropTypes.number,
  icon: PropTypes.string,
  size: PropTypes.oneOf(Object.values(sizes)),
  text: PropTypes.string,
};

export default IconLabel;
