import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import cn from 'classnames';

const ImageCollageBlock = ({
  leftTopImage,
  leftBottomImage,
  rightTopImage,
  rightBottomImage,
}) => {
  return (
    <div className="image-collage-block">
      <div className="image-collage-block__container">
        <div className="image-collage-block__col image-collage-block__col_left">
          {leftTopImage && (
            <Image
              {...leftTopImage}
              className="image-collage-block__image image_size_collage-left-top"
            />
          )}
          {leftBottomImage && (
            <Image
              {...leftBottomImage}
              className="image-collage-block__image image_size_collage-left-bottom"
            />
          )}
        </div>
        <div
          className={cn(
            'image-collage-block__col image-collage-block__col_right',
            {
              'image-collage-block__col_right_reversed':
                !leftTopImage ||
                !leftBottomImage ||
                !rightTopImage ||
                !rightBottomImage,
            }
          )}
        >
          {rightTopImage && (
            <Image
              {...rightTopImage}
              className="image-collage-block__image image_size_collage-right-top"
            />
          )}
          {rightBottomImage && (
            <Image
              {...rightBottomImage}
              className="image-collage-block__image image_size_collage-right-bottom"
            />
          )}
        </div>
      </div>
    </div>
  );
};

ImageCollageBlock.propTypes = {
  leftTopImage: PropTypes.exact(Image.propTypes),
  leftBottomImage: PropTypes.exact(Image.propTypes),
  rightTopImage: PropTypes.exact(Image.propTypes),
  rightBottomImage: PropTypes.exact(Image.propTypes),
};

export default ImageCollageBlock;
